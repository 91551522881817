import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Drawer from '@vms/Drawer';

const Layout: FC = () => {
    const location = useLocation();

    useEffect(() => {
        document.scrollingElement?.scrollTo({ top: 0 });
    }, [location]);

    return (
        <div className="Layout">
            <Drawer>
                <Outlet />
            </Drawer>
        </div>
    );
};

export default Layout;
