import { useCallback } from 'react';
import TextField, { TextFieldVariants, BaseTextFieldProps } from '@mui/material/TextField';

import { FCX } from '@core/models';

export enum TextInputType {
    TEXT = 'text',
    NUMBER = 'number',
}

const NUMBER_REGEX = /^[0-9\b]+$/;

const TextInput: FCX<{
    type?: TextInputType;
    value: string;
    setValue: (_value: string) => void;
    onBlur?: BaseTextFieldProps['onBlur'];
    placeholder?: string;
    label?: string;
    isDisabled?: boolean;
    isSmall?: boolean;
    variant?: TextFieldVariants;
    onKeyDown?: BaseTextFieldProps['onKeyDown'];
}> = ({
    type = TextInputType.TEXT,
    value,
    setValue,
    onBlur,
    placeholder,
    isDisabled = false,
    isSmall = false,
    variant,
    label,
    onKeyDown,
}) => {
    const onChange = useCallback(
        (val: string | undefined) => {
            if (val === undefined) {
                return;
            }

            if (type === TextInputType.NUMBER) {
                if (val === '' || NUMBER_REGEX.test(val)) {
                    if (val.length > 1 && val[0] === '0') {
                        return setValue(val.slice(1));
                    }
                    return setValue(val);
                }

                return;
            }

            setValue(val);
        },
        [setValue, type],
    );

    return (
        <TextField
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            disabled={isDisabled}
            classes={{
                root: 'TextInput',
            }}
            InputProps={{
                classes: !variant
                    ? {
                        root: `${isSmall ? 'TextInput__root--small' : 'TextInput__root'}`,
                        input: 'TextInput__input',
                    }
                    : undefined,
            }}
            fullWidth
            variant={variant}
            label={label}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
        />
    );
};

export default TextInput;
