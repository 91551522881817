import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { Header } from '@vms/Header';
import { IconPlus } from '@svg/index';
import Button from '@components/Button';
import { Table } from '@components/Table';
import { Route } from '@core/routes/Route';
import { TextInput } from '@components/Inputs';
import { replaceRouteParam } from '@core/utils/string';
import { useExportLiveSurveyXls } from '@core/api/useSurveyFile';
import useTableQuickSearch from '@core/hooks/useTableQuickSearch';
import { ExtendedSurvey, useGetSurveyInitialData, useGetSurveys } from '@core/api/useSurveys';

import NewSurveyPopup from './components/NewSurveyPopup';
import CloseSurveyPopup from './components/CloseSurveyPopup';
import LaunchSurveyPopup from './components/LaunchSurveyPopup';
import SurveysTableConfig from './components/SurveysTableConfig';

const Surveys = () => {
    const [isCloseSurveyPopupOpen, setIsCloseSurveyPopupOpen] = useState(false);
    const [isLaunchSurveyPopupOpen, setIsLaunchSurveyPopupOpen] = useState(false);
    const [isNewSurveyPopupOpen, setIsNewSurveyPopupOpen] = useState(false);
    const [selectedSurveyId, setSelectedSurveyId] = useState<number | undefined>(undefined);

    const navigate = useNavigate();

    const {
        data: surveysData,
        doFetch: fetchSurveys,
        isLoading
    } = useGetSurveys();

    const {
        searchValue,
        setSearchValue,
        searchResults
    } = useTableQuickSearch(surveysData ?? [], (s) => s?.name);

    const {
        data: surveyInitialData,
        doFetch: fetchInitialData
    } = useGetSurveyInitialData();

    const {
        isLoading: isExportLoading,
        doFetchAndDownload
    } = useExportLiveSurveyXls();

    useEffect(() => {
        fetchInitialData({ params: { countryId: 1 } });
    }, [fetchInitialData]);

    const tableActions = useMemo(
        () => ({
            onEdit: (survey: ExtendedSurvey) => {
                if (!survey.id) return;

                const surveyDetailsUrl = replaceRouteParam(Route.SurveyDetails, ':id', `${survey.id}`);
                navigate(surveyDetailsUrl);
            },
            onLaunch: (survey: ExtendedSurvey) => {
                if (!survey.id) return;

                setSelectedSurveyId(survey.id);
                setIsLaunchSurveyPopupOpen(true);
            },
            onClose: (survey: ExtendedSurvey) => {
                if (!survey.id) return;

                setSelectedSurveyId(survey.id);
                setIsCloseSurveyPopupOpen(true);
            },
        }),
        [navigate],
    );

    return (
        <div className="Surveys">
            <Header
                rightContent={
                    <div className="ChainItems__actions">
                        <Button modifiers={['h-tall']} onClick={() => setIsNewSurveyPopupOpen(true)}>
                            <IconPlus />
                            New Survey
                        </Button>
                        <Button modifiers={['h-tall']} onClick={isExportLoading ? undefined : doFetchAndDownload}>
                            {isExportLoading ? (
                                <>
                                    <CircularProgress size={13} color="inherit" />
                                    Export in progress
                                </>
                            ) : (
                                <>Live Survey Export</>
                            )}
                        </Button>
                        <TextInput value={searchValue} setValue={setSearchValue} placeholder="Search surveys" />
                    </div>
                }
            >
                Surveys
            </Header>

            <NewSurveyPopup
                open={isNewSurveyPopupOpen}
                handleClosePopup={() => setIsNewSurveyPopupOpen(false)}
                surveyInitialData={surveyInitialData}
            />

            <LaunchSurveyPopup
                open={isLaunchSurveyPopupOpen}
                handleClosePopup={() => setIsLaunchSurveyPopupOpen(false)}
                onLaunchSurveyCallback={fetchSurveys}
                surveyId={selectedSurveyId}
            />

            <CloseSurveyPopup
                open={isCloseSurveyPopupOpen}
                handleClosePopup={() => setIsCloseSurveyPopupOpen(false)}
                onCloseSurveyCallback={fetchSurveys}
                surveyId={selectedSurveyId}
            />

            <SurveysTableConfig
                data={searchResults}
                actions={tableActions}
                customFielding={surveyInitialData?.customFielding}
            >
                <Table rowsCount={searchResults.length} isLoading={isLoading} />
            </SurveysTableConfig>
        </div>
    );
};

export default Surveys;
