import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotifications } from '@toolpad/core/useNotifications';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Route } from '@core/routes/Route';
import { components } from '@core/api/api';
import { replaceRouteParam } from '@core/utils/string';
import { useGetSurveyDetails } from '@core/api/useSurveys';
import { SurveyStatusIdEnum } from '@core/hooks/useCommonEnums';
import { useSaveSurveyItems, useSurveyAvailableItems } from '@core/api/useSurveyItems';

type SurveyDetails = components['schemas']['SurveyDetails'];
type SurveyDetailsItem = components['schemas']['SurveyDetailsItem'];

const useSurveyAddItems = () => {
    const [surveyData, setSurveyData] = useState<SurveyDetails | null>(null);

    const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('day'));
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(1, 'year').startOf('day'));

    const [newIncludedItems, setNewIncludedItems] = useState<SurveyDetailsItem[]>([]);
    const [newAvailableItems, setNewAvailableItems] = useState<SurveyDetailsItem[]>([]);

    const navigate = useNavigate();
    const { state } = useLocation();
    const { id: surveyId } = useParams();
    const notifications = useNotifications();
    const { doFetch: saveSurveyItems } = useSaveSurveyItems();
    const { doFetch: fetchSurveyDetails } = useGetSurveyDetails(surveyId, true);

    const onNavigateToSurveyDetails = useCallback(
        (state?: unknown) => navigate(replaceRouteParam(Route.SurveyDetails, ':id', surveyId ?? ''), { state }),
        [navigate, surveyId],
    );

    const onIncludeNewItem = useCallback((item: SurveyDetailsItem) => {
        setNewIncludedItems((prevIncludedItems) => [...prevIncludedItems, item]);
        setNewAvailableItems((prevAvailableItems) => prevAvailableItems.filter((i) => i.id !== item.id));
    }, []);

    const onRemoveIncludedItem = useCallback((item: SurveyDetailsItem) => {
        setNewIncludedItems((prevIncludedItems) => prevIncludedItems.filter((i) => i.id !== item.id));
        setNewAvailableItems((prevAvailableItems) => [...prevAvailableItems, item]);
    }, []);

    const {
        data: availableItemsData,
        doFetch: fetchAvailableItems,
        isLoading: isAvailableItemsLoading,
    } = useSurveyAvailableItems();

    useEffect(() => {
        if (!state?.survey) {
            fetchSurveyDetails().then((res) => setSurveyData(res?.data ?? null));
        } else {
            setSurveyData(state.survey);
        }
    }, [fetchSurveyDetails, state?.survey]);

    useEffect(() => {
        fetchAvailableItems({
            data: {
                startDate: startDate.format(),
                endDate: endDate.format(),
                countryId: 1,
            },
        });
    }, [endDate, fetchAvailableItems, startDate]);

    useEffect(() => {
        if (surveyData?.status !== undefined && surveyData.status !== SurveyStatusIdEnum.Draft) {
            notifications.show('Only Draft surveys can add new Items', {
                severity: 'error',
                autoHideDuration: 5000,
            });

            onNavigateToSurveyDetails();
        }
    }, [surveyData?.status, navigate, notifications, onNavigateToSurveyDetails]);

    const filterDuplicates = useCallback(
        (item: SurveyDetailsItem) =>
            !newIncludedItems.some((ni) => ni.id === item.id) && !newAvailableItems.some((ni) => ni.id === item.id),
        [newAvailableItems, newIncludedItems],
    );

    const availableItems = useMemo(() => {
        // I need to Filter the newAvailableItems because the start date and end date filters are not applied to them
        const filteredNewAvailableItems = newAvailableItems.filter((i) => {
            const itemDate = dayjs(i.date);
            return (
                (itemDate.isAfter(startDate) && itemDate.isBefore(endDate)) ||
                itemDate.isSame(startDate) ||
                itemDate.isSame(endDate)
            );
        });

        return [...(availableItemsData ?? []).filter(filterDuplicates), ...filteredNewAvailableItems];
    }, [availableItemsData, endDate, filterDuplicates, newAvailableItems, startDate]);

    const includedItems = useMemo(
        () => [...(surveyData?.items ?? []).filter(filterDuplicates), ...newIncludedItems],
        [surveyData?.items, filterDuplicates, newIncludedItems],
    );

    const onSaveIncludedItems = useCallback(() => {
        if (surveyId) {
            saveSurveyItems({
                data: {
                    surveyId: parseInt(surveyId),
                    selectedItems: includedItems.flatMap((i) => i?.id ?? []),
                },
            })
                .then((res) => {
                    if (res?.status === 200) {
                        notifications.show('Items saved successfully', { severity: 'success' });
                        return onNavigateToSurveyDetails();
                    }

                    notifications.show('Failed to save items', { severity: 'error' });
                })
                .catch(() => {
                    notifications.show('Failed to save items', { severity: 'error' });
                });
        }
    }, [includedItems, notifications, onNavigateToSurveyDetails, saveSurveyItems, surveyId]);

    return {
        filters: {
            endDate,
            setEndDate,
            startDate,
            setStartDate,
        },
        actions: {
            onNavigateToSurveyDetails,
            onIncludeNewItem,
            onRemoveIncludedItem,
            onSaveIncludedItems,
        },
        data: {
            surveyData,
            availableItems,
            includedItems,
            newIncludedItems,
            newAvailableItems,
        },
        isAvailableItemsLoading,
    };
};

export default useSurveyAddItems;
