import { FunctionComponent, SVGProps } from 'react';

import { ReactComponent as Caret } from './caret.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Sort } from './sort.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Surveys } from './surveys.svg';
import { ReactComponent as CountrySettings } from './country-settings.svg';
import { ReactComponent as PublicItemsLocker } from './public-items-locker.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as MoveUp } from './move-up.svg';

export type IconType = FunctionComponent<SVGProps<SVGSVGElement>>;

export const IconCaret: IconType = Caret;
export const IconMenu: IconType = Menu;
export const IconPencil: IconType = Pencil;
export const IconPlus: IconType = Plus;
export const IconSort: IconType = Sort;
export const IconTrash: IconType = Trash;
export const IconSurveys: IconType = Surveys;
export const IconCountrySettings: IconType = CountrySettings;
export const IconPublicItemsLocker: IconType = PublicItemsLocker;
export const IconCheck: IconType = Check;
export const IconMoveUp: IconType = MoveUp;
