import { ReactNode } from 'react';
import cn from 'classnames';

import { FCX } from '@models';
import { IconCheck } from '@svg/index';

export enum ToggleType {
    Switch = 'switch',
    Checkbox = 'check',
}

const Toggle: FCX<{
    isChecked: boolean;
    isDisabled?: boolean;
    onChange: () => void;
    label?: ReactNode;
    type?: ToggleType;
    labelPosition?: 'right' | 'left';
}> = ({
    type = ToggleType.Switch,
    isChecked,
    isDisabled = false,
    onChange,
    label,
    labelPosition = 'right',
    className,
    style,
    ...attrs
}) => {
    return (
        <div
            onClick={onChange}
            className={cn('Toggle', className, `is-${type}`, {
                'is-disabled': isDisabled,
                'is-checked': isChecked,
            })}
            {...attrs}
            style={{
                ...style,
                flexDirection: labelPosition === 'right' ? 'row' : 'row-reverse',
            }}
        >
            <div className={'Toggle__icon'}>
                {type === ToggleType.Checkbox && <IconCheck style={{ width: 10, height: 10 }} />}
            </div>
            {!!label && <div className={'Toggle__label'}>{label}</div>}
        </div>
    );
};

export default Toggle;
