import { components } from '@core/api/api';
import { AxiosResponse, AxiosHeaders } from 'axios';

export type ApiTableExportFilter = components['schemas']['TableExportFilter'];

type NavigatorIncludesMS = Navigator & { msSaveBlob?: (blob: Blob, filename?: string) => void };

export function downloadFile(url: string, filename?: string): void {
    const link = document.createElement('a');

    if (typeof link.download === 'undefined') {
        window.location.href = url;
    } else {
        link.href = url;
        link.download = filename || 'true';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function downloadBlobFile(blob: Blob, filename?: string) {
    const navigator = window.navigator as NavigatorIncludesMS;

    if (navigator.msSaveBlob && typeof navigator.msSaveBlob !== 'undefined') {
        navigator.msSaveBlob(blob, filename);
    } else {
        const URL = window.URL || window.webkitURL;
        const downloadUrl = URL.createObjectURL(blob);

        if (filename) {
            downloadFile(downloadUrl, filename);
        } else {
            window.location.href = downloadUrl;
        }

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100);
    }
}

export function getFilenameFromContentDisposition(disposition: string | null): string {
    let filename = '';
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        const matches = disposition.match(filenameRegex);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
}

export async function downloadExport(response: void | AxiosResponse<BlobPart>, fallbackFilename: string) {
    if (response && response.status === 200 && response.data) {
        const blob = new Blob([response.data]);
        const disposition = (response.headers as AxiosHeaders).get('Content-Disposition');
        const filename = getFilenameFromContentDisposition(disposition as string) || fallbackFilename;
        downloadBlobFile(blob, filename);
    }
    return;
}

export function resizeImageFile(file: File): Promise<Blob> {
    const maxWidth = 800;
    const maxHeight = 800;

    return new Promise<Blob>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = (error) => reject(error);
        reader.onload = (event) => {
            const img = new Image();
            img.src = event?.target?.result as string;
            img.onload = (i) => {
                const {
                    width,
                    height
                } = i.target as HTMLImageElement;
                const elem = document.createElement('canvas');
                const scaleFactor = Math.min(maxWidth / width, maxHeight / height);

                elem.width = width * scaleFactor;
                elem.height = height * scaleFactor;

                const ctx = elem.getContext('2d') as CanvasRenderingContext2D;
                ctx.drawImage(img, 0, 0, width, height, 0, 0, width * scaleFactor, height * scaleFactor);

                ctx.canvas.toBlob((blob) => {
                    if (!blob) {
                        reject(new Error('Failed to convert canvas to blob'));
                    } else {
                        resolve(blob);
                    }
                });
            };
        };
    });
}
