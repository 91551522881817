import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '@toolpad/core/useNotifications';

import Dialog from '@mui/material/Dialog';
import MuiButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Button from '@components/Button';
import useForm from '@core/hooks/useForm';
import { Route } from '@core/routes/Route';
import { components } from '@core/api/api';
import { FCX, iOption } from '@core/models';
import { TextInput } from '@components/Inputs';
import { Select } from '@components/Inputs/Select';
import { replaceRouteParam } from '@core/utils/string';
import { TextInputType } from '@components/Inputs/TextInput/TextInput';
import { BodySaveSurvey, GetSurveyInitialDataResponse, useSaveSurvey } from '@core/api/useSurveys';

import './NewSurveyPopup.scss';

type ItemsPerRespondentKeys = Extract<keyof components['schemas']['ItemsPerRespondent'], string>;

const NewSurveyPopup: FCX<{
    open: boolean;
    handleClosePopup: () => void;
    surveyInitialData: GetSurveyInitialDataResponse | null;
}> = ({
    open,
    handleClosePopup,
    surveyInitialData
}) => {
    const navigate = useNavigate();
    const notifications = useNotifications();
    const { doFetch: saveSurvey } = useSaveSurvey();

    const customFieldingOptions: iOption[] = useMemo(
        () => surveyInitialData?.customFielding?.map((cf) => ({ id: cf?.id ?? '', title: cf?.name ?? '' })) ?? [],
        [surveyInitialData?.customFielding],
    );

    const defaultFormState: BodySaveSurvey = useMemo(
        () => ({
            id: 0,
            countryId: 1,
            itemsRatio: surveyInitialData?.defaultItemsRatios,
            itemsPerRespondent: {
                express: 1,
                regular: 0,
                conceptLocker: 0,
                scoresPlus: 0,
                scoresEnterprise: 0,
            },
        }),
        [surveyInitialData?.defaultItemsRatios],
    );

    const {
        state,
        actions
    } = useForm<BodySaveSurvey>(defaultFormState);

    const onClosePopup = useCallback(() => {
        actions.resetForm();
        handleClosePopup();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleClosePopup]);

    const onSaveSurvey = useCallback(() => {
        const parsedState: BodySaveSurvey = {
            ...state,
            itemsPerRespondent: Object.entries(state.itemsPerRespondent ?? {}).reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value || 0 }),
                {},
            ),
        };

        const perRespondentSum = Object.values<string | number>(parsedState?.itemsPerRespondent ?? {}).reduce(
            (acc: number, val) => acc + Number(val),
            0,
        );

        if (perRespondentSum === 0) {
            notifications.show('The total sum of the "per respondent" item sample sizes must be greater than 0', {
                severity: 'warning',
                autoHideDuration: 5000,
            });
            return;
        }

        saveSurvey({ data: parsedState })
            .then((r) => {
                if (r?.status === 200) {
                    notifications.show('Survey saved successfully', {
                        severity: 'success',
                        autoHideDuration: 5000,
                    });

                    if (r.data) {
                        const surveyDetailsUrl = replaceRouteParam(Route.SurveyDetails, ':id', `${r.data}`);
                        return navigate(surveyDetailsUrl);
                    }
                } else {
                    notifications.show('Survey save failed', {
                        severity: 'error',
                        autoHideDuration: 5000,
                    });
                }
            })
            .catch(() => {
                notifications.show('Survey save failed', {
                    severity: 'error',
                    autoHideDuration: 5000,
                });
            });
    }, [navigate, notifications, saveSurvey, state]);

    const onBlurPerRespondent = useCallback(
        (field: ItemsPerRespondentKeys, count?: number) => {
            if (!count) {
                actions.setNestedField(`itemsPerRespondent.${field}`, '0');
            }
        },
        [actions],
    );

    return (
        <Dialog
            open={open}
            onClose={onClosePopup}
            className="NewSurveyPopup"
            classes={{ paper: 'NewSurveyPopup__container' }}
        >
            <DialogTitle>{'Create Survey'}</DialogTitle>
            <DialogContent>
                <div>
                    <div className="NewSurveyPopup__row">
                        <TextField
                            autoFocus
                            label="Custom Name"
                            fullWidth
                            variant="standard"
                            value={state.customName}
                            onChange={(event) => actions.setField('customName', event.target.value)}
                        />
                        <Select
                            options={customFieldingOptions}
                            label="Custom Fielding"
                            value={String(state?.customFielding ?? '')}
                            setValue={(v) => actions?.setField('customFielding', v)}
                        />
                    </div>
                    <div className="NewSurveyPopup__row">Per Respondent</div>
                    <div className="NewSurveyPopup__row">
                        <TextInput
                            variant="standard"
                            label="Express Items"
                            type={TextInputType.NUMBER}
                            value={`${state?.itemsPerRespondent?.express ?? ''}`}
                            setValue={(v) => actions.setNestedField('itemsPerRespondent.express', v)}
                            onBlur={() => onBlurPerRespondent('express', state?.itemsPerRespondent?.express)}
                        />

                        <TextInput
                            variant="standard"
                            label="Full Concepts"
                            type={TextInputType.NUMBER}
                            value={`${state?.itemsPerRespondent?.conceptLocker ?? ''}`}
                            setValue={(v) => actions.setNestedField('itemsPerRespondent.conceptLocker', v)}
                            onBlur={() =>
                                onBlurPerRespondent('conceptLocker', state?.itemsPerRespondent?.conceptLocker)
                            }
                        />
                    </div>
                    <div className="NewSurveyPopup__row">
                        <TextInput
                            variant="standard"
                            label="Public Items"
                            type={TextInputType.NUMBER}
                            value={`${state?.itemsPerRespondent?.regular ?? ''}`}
                            setValue={(v) => actions.setNestedField('itemsPerRespondent.regular', v)}
                            onBlur={() => onBlurPerRespondent('regular', state?.itemsPerRespondent?.regular)}
                        />

                        <TextInput
                            variant="standard"
                            label="Scores Plus Items"
                            type={TextInputType.NUMBER}
                            value={`${state?.itemsPerRespondent?.scoresPlus ?? ''}`}
                            setValue={(v) => actions.setNestedField('itemsPerRespondent.scoresPlus', v)}
                            onBlur={() => onBlurPerRespondent('scoresPlus', state?.itemsPerRespondent?.scoresPlus)}
                        />
                    </div>
                    <div className="NewSurveyPopup__row NewSurveyPopup__row--half">
                        <TextInput
                            variant="standard"
                            label="Scores Enterprise Items"
                            type={TextInputType.NUMBER}
                            value={`${state?.itemsPerRespondent?.scoresEnterprise ?? ''}`}
                            setValue={(v) => actions.setNestedField('itemsPerRespondent.scoresEnterprise', v)}
                            onBlur={() =>
                                onBlurPerRespondent('scoresEnterprise', state?.itemsPerRespondent?.scoresEnterprise)
                            }
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <MuiButton color="inherit" onClick={onClosePopup}>
                    Cancel
                </MuiButton>
                <Button onClick={onSaveSurvey} isDisabled={(state?.customName ?? '').length < 3}>
                    Save Survey
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewSurveyPopup;
