import { CSSProperties, FC, ReactNode } from 'react';
import cn from 'classnames';
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell';

const TableCell: FC<{
    children: ReactNode;
    className?: string;
    align?: TableCellProps['align'];
    onClick?: () => void;
    colSpan?: number;
    styles?: CSSProperties;
}> = ({
    children,
    className = '',
    align,
    onClick,
    colSpan,
    styles
}) => {
    return (
        <MuiTableCell
            onClick={onClick}
            classes={{
                root: cn('Table__cell', className),
            }}
            align={align}
            colSpan={colSpan}
            style={styles}
        >
            {children}
        </MuiTableCell>
    );
};

export default TableCell;
