import dayjs from 'dayjs';
import { useMemo } from 'react';
import { IconButton } from '@mui/material';

import { FCX } from '@models';
import { IconPencil, IconTrash } from '@svg/index';
import { formatDefaultDate } from '@core/utils/string';
import { TableContext } from '@components/Table/TableContext';
import LineEllipsis from '@components/LineEllipsis/LineEllipsis';
import { ChainDetailsMenuItem } from '@core/api/useChainDetails';
import useCommonEnums, { customStatusesMap } from '@core/hooks/useCommonEnums';
import { iTableConfigColumnGroups, iTableConfigItem } from '@components/Table/TableConfig';
import useTableSort from '@core/hooks/useTableSort';

const ChainItemsTableConfig: FCX<{
    data: ChainDetailsMenuItem[];
    onEditChainItem: (_data: ChainDetailsMenuItem) => void;
    onDeleteMenuItem: (_itemId?: number) => void;
}> = ({
    data,
    children,
    onEditChainItem,
    onDeleteMenuItem
}) => {
    const timestamp = useMemo(
        () => Date.now(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data],
    );

    const { findCommonNameById } = useCommonEnums();

    const {
        sortedResults,
        sortConfig
    } = useTableSort(data, 'entryDate');

    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'displayDate',
                title: 'Display Date',
                Value: ({ index }) => {
                    const displayDate = sortedResults[index]?.displayDate;

                    if (!displayDate) {
                        return null;
                    }

                    return <>{dayjs(displayDate).format('MMM YY')}</>;
                },
                width: '65px',
            },
            {
                key: 'itemImage',
                title: 'Item Image',
                Value: ({ index }) => (
                    <img className="ChainItems__item-image" src={sortedResults[index]?.smallImageUrl ? `${sortedResults[index]?.smallImageUrl}?t=${timestamp}` :  ''} />
                ),
                width: '60px',
            },
            {
                key: 'itemName',
                sortingColName: 'name',
                title: 'Item Name',
                Value: ({ index }) => <>{sortedResults[index]?.name}</>,
                width: '160px',
            },
            {
                key: 'itemDescription',
                title: 'Item Description',
                Value: ({ index }) => (
                    <LineEllipsis
                        lines={2}
                        lineHeight={16.8}
                        textContent={sortedResults[index]?.description ?? ''}
                    ></LineEllipsis>
                ),
                width: '220px',
            },
            {
                key: 'note',
                title: 'Note',
                Value: ({ index }) => <>{sortedResults[index]?.note}</>,
                width: '180px',
            },
            {
                key: 'price',
                title: 'Price',
                Value: ({ index }) => <>{sortedResults[index]?.price}</>,
                width: '90px',
            },
            {
                key: 'status',
                title: 'Status',
                Value: ({ index }) => {
                    const kindId = sortedResults[index]?.kindId;

                    if (!kindId) {
                        return null;
                    }

                    return <>{findCommonNameById('itemKinds', kindId)}</>;
                },
                width: '100px',
            },
            {
                key: 'itemType',
                title: 'Item Type',
                Value: ({ index }) => {
                    const itemCategoryId = sortedResults[index]?.categoryId;

                    if (!itemCategoryId) {
                        return null;
                    }

                    return <>{findCommonNameById('itemCategories', itemCategoryId)}</>;
                },
                width: '110px',
            },
            {
                key: 'uiStatus',
                title: 'UI Status',
                Value: ({ index }) => {
                    const statusId = sortedResults[index]?.statusId;

                    if (!statusId) {
                        return null;
                    }

                    return <>{customStatusesMap?.[statusId as keyof typeof customStatusesMap] ?? ''}</>;
                },
                width: '70px',
            },
            {
                key: 'itemEntryDate',
                sortingColName: 'entryDate',
                title: 'Item Entry Date',
                Value: ({ index }) => {
                    const entryDate = sortedResults[index]?.entryDate;

                    if (!entryDate) {
                        return null;
                    }

                    return <>{formatDefaultDate(sortedResults[index]?.entryDate)}</>;
                },
                width: '90px',
            },
            {
                key: 'action',
                title: '',
                Value: ({ index }) => (
                    <div className="ChainItems__item-actions">
                        <IconButton onClick={() => onEditChainItem(sortedResults[index])}>
                            <IconPencil />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                if (sortedResults[index]?.id) {
                                    onDeleteMenuItem(sortedResults[index]?.id);
                                }
                            }}
                        >
                            <IconTrash />
                        </IconButton>
                    </div>
                ),
                width: '80px',
            },
        ],
        [findCommonNameById, onDeleteMenuItem, onEditChainItem, sortedResults, timestamp],
    );

    const columnGroupsConfig = useMemo(
        (): iTableConfigColumnGroups[] => [
            {
                title: 'Displayed Info',
                columnSpan: 8,
                key: 'displayedInfo',
            },
            {
                title: 'Internal',
                columnSpan: 2,
                key: 'internal',
            },
            {
                title: '',
                columnSpan: 1,
                key: 'action',
            },
        ],
        [],
    );

    return (
        <TableContext.Provider value={{ config: tableConfig, configColumnGroups: columnGroupsConfig, sortConfig }}>
            {children}
        </TableContext.Provider>
    );
};

export default ChainItemsTableConfig;
