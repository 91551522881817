import { useCallback } from 'react';

import { Header } from '@vms/Header';
import { Table } from '@components/Table';
import { TogglePublicItemsParams, useToggleCountryPublicItems } from '@core/api/useCountrySettings';

import { useAppContext } from '../../App.context';
import CountrySettingsTableConfig from './CountrySettingsTableConfig';

const CountrySettings = () => {
    const {
        countrySettings,
        isLoading,
        fetchCountrySettings
    } = useAppContext();

    const {
        doFetch: toggleCountryPublicItems,
        isLoading: isToggleLoading
    } = useToggleCountryPublicItems();

    const onToggleCountryPulicItems = useCallback(
        (params: TogglePublicItemsParams) => {
            toggleCountryPublicItems({
                params,
            }).then(() => {
                fetchCountrySettings?.();
            });
        },
        [fetchCountrySettings, toggleCountryPublicItems],
    );

    return (
        <>
            <Header>Country Settings</Header>
            <CountrySettingsTableConfig data={countrySettings} onToggleCountryPulicItems={onToggleCountryPulicItems}>
                <Table
                    rowsCount={countrySettings ? countrySettings.length : 0}
                    isLoading={isLoading || isToggleLoading}
                />
            </CountrySettingsTableConfig>
        </>
    );
};

export default CountrySettings;
