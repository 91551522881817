import { ReactNode, useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { api } from '@hooks/useFetch';

const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
    const [isSet, setIsSet] = useState(false);

    const onResponse = (response: AxiosResponse): AxiosResponse => {
        // const { method, url } = response.config;
        // const { status } = response;

        return response;
    };

    const onErrorResponse = (error: AxiosError): Promise<AxiosError> => {
        if (axios.isAxiosError(error)) {
            const { status } = (error.response as AxiosResponse) ?? {};

            if (status === 401) {
                window.location.href = `${process.env.REACT_APP_API}/userAuth?returnUrl=${window.location.href}`;
            }

            if (status === 403) {
                window.location.href = `${process.env.REACT_APP_SNAP}`;
            }

            if (status === 415) {
                return Promise.resolve(error);
            }
        }
        return Promise.reject(error);
    };

    useEffect(() => {
        const responseInterceptor = api.interceptors.response.use(onResponse, onErrorResponse);
        setIsSet(true);

        return () => api.interceptors.response.eject(responseInterceptor);
    }, []);

    return <>{isSet && children}</>;
};

export default AxiosInterceptor;
