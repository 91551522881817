import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { iOption } from '@core/models';
import {
    ToggleChainActiveStatusParams,
    useGetPublicItemsLockerChains,
    useToggleChainActiveStatus,
} from '@core/api/usePublicItemsLocker';
import useTableQuickSearch from '@core/hooks/useTableQuickSearch';
import { ExportPopupOptions } from '@vms/ExportPopup/ExportPopup';
import { useExportPublicItemsXls } from '@core/api/usePublicItemsLockerFile';

import { useAppContext } from '../../App.context';

const DEFAULT_MINIMUM_CHAIN_UNITS = '50';

enum PublicItemsSearchParams {
    COUNTRY_ID = 'countryId',
    CHAIN_UNIT_MINIMUM = 'chainUnitMinimum',
}

const usePublicItemsLocker = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
    const [selectedCountryId, setSelectedCountryId] = useState<string>('');
    const [chainUnitsInput, setChainUnitsInput] = useState<string>(DEFAULT_MINIMUM_CHAIN_UNITS);
    const [activeChainUnitsMinimum, setActiveChainUnitsMinimum] = useState<string | null>(DEFAULT_MINIMUM_CHAIN_UNITS);

    const { countrySettings } = useAppContext();

    const {
        doFetch: toggleChainActiveStatus,
        isLoading: isToggleLoading
    } = useToggleChainActiveStatus();

    const {
        data,
        isLoading,
        doFetch: doFetchChains
    } = useGetPublicItemsLockerChains();

    const { doFetchAndDownload } = useExportPublicItemsXls();

    const {
        searchValue,
        setSearchValue,
        searchResults
    } = useTableQuickSearch(data ?? [], (item) => item?.name);

    const countryOptions: iOption[] = useMemo(() => {
        return countrySettings.map((country) => ({
            id: country?.id ?? '',
            title: `${country.countryCode} - ${country?.name}`,
        }));
    }, [countrySettings]);

    const countryName = useMemo(
        () => countrySettings.find((c) => c?.id === Number(selectedCountryId))?.name ?? '',
        [countrySettings, selectedCountryId],
    );

    const onExportPublicItems = useCallback(
        (options: ExportPopupOptions) => {
            if (!selectedCountryId) {
                return;
            }

            doFetchAndDownload({
                countryId: parseInt(selectedCountryId),
                ...options,
            });

            setIsExportPopupOpen(false);
        },
        [doFetchAndDownload, selectedCountryId],
    );

    const onFetchChains = useCallback(() => {
        doFetchChains({
            params: {
                countryId: selectedCountryId,
                ...(activeChainUnitsMinimum ? { chainUnitMinimum: activeChainUnitsMinimum } : {}),
            },
        });
    }, [activeChainUnitsMinimum, doFetchChains, selectedCountryId]);

    const onToggleChainActiveStatus = useCallback(
        (params: ToggleChainActiveStatusParams) => {
            toggleChainActiveStatus({
                params,
            }).then(() => onFetchChains());
        },
        [onFetchChains, toggleChainActiveStatus],
    );

    const onUpdateChainUnitMinimum = useCallback(() => {
        if (!chainUnitsInput) {
            setChainUnitsInput('0');
        }

        setActiveChainUnitsMinimum(chainUnitsInput || '0');
        setSearchParams((prev) => {
            prev.set(PublicItemsSearchParams.CHAIN_UNIT_MINIMUM, chainUnitsInput || '0');
            return prev;
        });
    }, [chainUnitsInput, setSearchParams]);

    useEffect(() => {
        if (selectedCountryId) {
            onFetchChains();
            setSearchParams((searchParams) => {
                searchParams.set(PublicItemsSearchParams.COUNTRY_ID, selectedCountryId);
                return searchParams;
            });
        }
    }, [selectedCountryId, onFetchChains, setSearchParams]);

    useEffect(() => {
        if (countryOptions && countryOptions.length > 0) {
            const countryIdFromUrl = searchParams.get(PublicItemsSearchParams.COUNTRY_ID);
            const chainUnitMinimumFromUrl =
                searchParams.get(PublicItemsSearchParams.CHAIN_UNIT_MINIMUM) ?? DEFAULT_MINIMUM_CHAIN_UNITS;

            setSelectedCountryId(countryIdFromUrl ?? `${countryOptions[0]?.id}`);
            setActiveChainUnitsMinimum(chainUnitMinimumFromUrl);
            setChainUnitsInput(chainUnitMinimumFromUrl);
        }
    }, [countryOptions, searchParams]);

    return {
        country: {
            selectedCountryId,
            setSelectedCountryId,
            countryOptions,
            countryName,
        },
        chains: {
            onToggleChainActiveStatus,
            onUpdateChainUnitMinimum,
            chainUnitsInput,
            setChainUnitsInput,
        },
        search: {
            results: searchResults,
            isLoading: isLoading || isToggleLoading,
            searchValue,
            setSearchValue,
        },
        exportXls: {
            isExportPopupOpen,
            setIsExportPopupOpen,
            onExportPublicItems,
        },
    };
};

export default usePublicItemsLocker;
