import useAxios from '@core/hooks/useFetch';
import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@core/models';

import { paths } from './api';

const GET_COUNTRY_SETTINGS = '/api/CountrySettings';
const POST_TOGGLE_PUBLIC_ITEMS = '/api/CountrySettings/ToggleIsPublicItemsEnabled';

type GetCountrySettings = paths[typeof GET_COUNTRY_SETTINGS][MethodsEnum.GET];
export type CountrySettingsResponse =
    GetCountrySettings[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];

type PostTogglePublicItems = paths[typeof POST_TOGGLE_PUBLIC_ITEMS][MethodsEnum.POST];
export type TogglePublicItemsParams = PostTogglePublicItems[OperationsEnum.PARAMETERS]['query'];

export function useGetCountrySettings() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<CountrySettingsResponse>({
        url: GET_COUNTRY_SETTINGS,
        method: MethodsEnum.GET,
    });

    return { data, isLoading, error, doFetch } as const;
}

export function useToggleCountryPublicItems() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<boolean>({
        url: POST_TOGGLE_PUBLIC_ITEMS,
        method: MethodsEnum.POST,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}
