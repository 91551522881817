import { FC } from 'react';
import { CircularProgress } from '@mui/material';

import DataFallback from '@components/DataFallback';

import { useTableContext } from './TableContext';

const FooterContent: FC<{
    isLoading: boolean;
    noResultsTitle?: string;
    noResultsDescription?: string;
    rowsCount: number;
}> = ({
    isLoading,
    noResultsTitle = 'Your search conditions do not match any results.',
    noResultsDescription = '',
    rowsCount,
}) => {
    const { config } = useTableContext();

    return (
        <>
            {!isLoading && rowsCount === 0 && (
                <tr>
                    <td colSpan={config.length}>
                        <DataFallback title={noResultsTitle} subTitle={noResultsDescription} />
                    </td>
                </tr>
            )}
            {isLoading && (
                <tr>
                    <td colSpan={config.length}>
                        <div className="Table__loader">
                            <CircularProgress size={60} />
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default FooterContent;
