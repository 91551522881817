import { useCallback } from 'react';

import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@core/models';
import useAxios from '@core/hooks/useFetch';
import { ApiTableExportFilter, downloadExport } from '@core/utils/file';

import { paths } from './api';

const POST_EXPORT_PUBLIC_ITEMS = '/api/PublicItemsLockerFile/XlsExport';
const POST_BULK_IMPORT = '/api/PublicItemsLockerFile/BulkImport';

type PostBulkImport = paths[typeof POST_BULK_IMPORT][MethodsEnum.POST];
export type PostBulkImportRequest = NonNullable<
    PostBulkImport[OperationsEnum.REQUEST]
>[Content][ContentTypeEnum.FormData];

export function useExportPublicItemsXls() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<never, ApiTableExportFilter>({
        url: POST_EXPORT_PUBLIC_ITEMS,
        method: MethodsEnum.POST,
        lazy: true,
        initialConfig: {
            maxBodyLength: Infinity,
            responseType: 'arraybuffer',
        },
    });

    const doFetchAndDownload = useCallback(
        (filters: ApiTableExportFilter) => {
            doFetch({
                data: filters,
            }).then((resp) => {
                downloadExport(resp, 'Chains.xlsx');
            });
        },
        [doFetch],
    );

    return { data, isLoading, error, doFetchAndDownload } as const;
}

export function useBulkImport() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<[], PostBulkImportRequest>({
        url: POST_BULK_IMPORT,
        method: MethodsEnum.POST,
        lazy: true,
        initialConfig: {
            maxBodyLength: Infinity,
            maxContentLength: Infinity,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    });

    return { data, isLoading, error, doFetch } as const;
}
