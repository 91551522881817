import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotifications } from '@toolpad/core/useNotifications';

import {
    BodySaveSurvey,
    GetSurveyInitialDataParams,
    SurveyDetailsItem,
    useGetSurveyDetails,
    useGetSurveyInitialData,
    useSaveSurvey,
} from '@core/api/useSurveys';
import { Route } from '@core/routes/Route';
import useForm, { UseFormReturn } from '@core/hooks/useForm';
import useTableQuickSearch from '@core/hooks/useTableQuickSearch';

type UseSurveyDetailsReturnType = {
    data: {
        surveyDetails: ReturnType<typeof useGetSurveyDetails>['data'];
        surveyItems: ReturnType<typeof useTableQuickSearch<SurveyDetailsItem>>;
        initialData: ReturnType<typeof useGetSurveyInitialData>['data'];
        isLoading: boolean;
    };
    form: UseFormReturn<BodySaveSurvey>;
    onDeleteItem: (itemId?: number) => void;
    onSaveSurvey: () => void;
};
const useSurveyDetails = (): UseSurveyDetailsReturnType => {
    const navigate = useNavigate();
    const { id: surveyId } = useParams();
    const notifications = useNotifications();

    const {
        state,
        actions
    } = useForm<BodySaveSurvey>({});

    const { doFetch: saveSurvey } = useSaveSurvey();

    const {
        data: surveyDetails,
        isLoading
    } = useGetSurveyDetails(surveyId);

    const {
        doFetch: fetchInitialData,
        data: initialData,
        isLoading: isInitialDataLoading
    } = useGetSurveyInitialData();

    useEffect(() => {
        if (surveyDetails) {
            const params: GetSurveyInitialDataParams = surveyDetails?.countryId
                ? { countryId: surveyDetails?.countryId }
                : undefined;

            fetchInitialData({ params }).then((res) => {
                if (res?.status === 200) {
                    actions.setFields({
                        id: surveyDetails?.id,
                        customName: surveyDetails?.customName,
                        status: surveyDetails?.status,
                        customFielding: surveyDetails?.customFielding,
                        itemsRatio: res.data?.defaultItemsRatios,
                        itemsPerRespondent: {
                            regular: surveyDetails?.itemsPerRespondent?.regular ?? 0,
                            conceptLocker: surveyDetails?.itemsPerRespondent?.conceptLocker ?? 0,
                            express: surveyDetails?.itemsPerRespondent?.express ?? 0,
                            scoresPlus: surveyDetails?.itemsPerRespondent?.scoresPlus ?? 0,
                            scoresEnterprise: surveyDetails?.itemsPerRespondent?.scoresEnterprise ?? 0,
                        },
                        selectedItems: surveyDetails?.items?.flatMap((item) => item?.id ?? []),
                        surveyGroups: surveyDetails?.surveyGroups,
                        surveyAgeGroups: surveyDetails?.surveyAgeGroups,
                        sampleProviders: surveyDetails?.sampleProviders,
                        ageLogic: surveyDetails?.ageLogic,
                        conceptsPriority: surveyDetails?.conceptsPriority,
                        isNdaEnabled: surveyDetails?.isNdaEnabled,
                        countryId: surveyDetails?.countryId,
                        surveyTypeId: surveyDetails?.surveyTypeId,
                        surveySourceTypeId: surveyDetails?.surveySourceTypeId,
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchInitialData, surveyDetails]);

    const selectedItems = useMemo(() => {
        return surveyDetails?.items?.filter((item) => state?.selectedItems?.includes(item?.id ?? 0));
    }, [surveyDetails?.items, state?.selectedItems]);

    const surveyItems = useTableQuickSearch(selectedItems ?? [], (s) => s?.itemName);

    const onDeleteItem = useCallback(
        (itemId?: number) => {
            actions.setField(
                'selectedItems',
                state?.selectedItems?.filter((id) => id !== itemId),
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state.selectedItems],
    );

    const onSaveSurvey = useCallback(() => {
        const perRespondentSum = Object.values(state?.itemsPerRespondent ?? {}).reduce(
            (acc, val) => acc + Number(val),
            0,
        );

        if (perRespondentSum === 0) {
            notifications.show('The total sum of the "per respondent" item sample sizes must be greater than 0', {
                severity: 'warning',
                autoHideDuration: 5000,
            });
            return;
        }

        saveSurvey({ data: state })
            .then((r) => {
                if (r?.status === 200) {
                    notifications.show('Survey saved successfully', {
                        severity: 'success',
                        autoHideDuration: 5000,
                    });

                    return navigate(Route.Surveys);
                }

                notifications.show('Survey save failed', {
                    severity: 'error',
                    autoHideDuration: 5000,
                });
            })
            .catch(() => {
                notifications.show('Survey save failed', {
                    severity: 'error',
                    autoHideDuration: 5000,
                });
            });
    }, [navigate, notifications, saveSurvey, state]);

    return {
        data: {
            surveyDetails,
            surveyItems,
            initialData,
            isLoading: isLoading || isInitialDataLoading,
        },
        form: {
            state,
            actions,
        },
        onDeleteItem,
        onSaveSurvey,
    };
};

export default useSurveyDetails;
