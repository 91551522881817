import { useMemo } from 'react';

import { FCX } from '@models';
import { Toggle } from '@components/Toggle';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigColumnGroups, iTableConfigItem } from '@components/Table/TableConfig';
import { CountrySettingsResponse, TogglePublicItemsParams } from '@core/api/useCountrySettings';
import { formatNumber } from '@core/utils/string';
import useTableSort from '@core/hooks/useTableSort';

const CountrySettingsTableConfig: FCX<{
    data: CountrySettingsResponse | null;
    onToggleCountryPulicItems: (_params: TogglePublicItemsParams) => void;
}> = ({
    data,
    children,
    onToggleCountryPulicItems
}) => {
    const {
        sortedResults,
        sortConfig
    } = useTableSort(data ?? []);

    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'id',
                title: 'Country ID',
                Value: ({ index }) => <>{sortedResults?.[index].id}</>,
            },
            {
                key: 'name',
                sortingColName: 'name',
                title: 'Country Name',
                Value: ({ index }) => <>{sortedResults?.[index].name}</>,
            },
            {
                key: 'countryCode',
                sortingColName: 'countryCode',
                title: 'Country Display Name',
                Value: ({ index }) => <>{sortedResults?.[index].countryCode}</>,
            },
            {
                key: 'itemsCount',
                sortingColName: 'itemsCount',
                title: 'Count of Public items',
                Value: ({ index }) => <>{formatNumber(sortedResults?.[index].itemsCount)}</>,
            },
            {
                key: 'chainsCount',
                sortingColName: 'chainsCount',
                title: 'Count of Chains with items',
                Value: ({ index }) => <>{formatNumber(sortedResults?.[index].chainsCount)}</>,
            },
            {
                key: 'isPublicItemsEnabled',
                sortingColName: 'isPublicItemsEnabled',
                title: 'Public Items',
                Value: ({ index }) => (
                    <Toggle
                        isChecked={sortedResults?.[index].isPublicItemsEnabled ?? false}
                        onChange={() => {
                            if (sortedResults?.[index].id) {
                                onToggleCountryPulicItems({
                                    countryId: sortedResults?.[index].id,
                                });
                            }
                        }}
                    ></Toggle>
                ),
            },
            {
                key: 'isConceptLockerEnabled',
                title: 'Concept Locker',
                Value: ({ index }) => {
                    const isConceptLockerDisabled = true;

                    return (
                        <Toggle
                            isChecked={
                                isConceptLockerDisabled
                                    ? false
                                    : (sortedResults?.[index].isConceptLockerEnabled ?? false)
                            }
                            isDisabled={true}
                            onChange={() => {
                                console.log('Toggle clicked');
                            }}
                        ></Toggle>
                    );
                },
            },
        ],
        [onToggleCountryPulicItems, sortedResults],
    );

    const configColumnGroups = useMemo(
        (): iTableConfigColumnGroups[] => [
            {
                title: 'Available Data',
                columnSpan: 5,
                key: 'availableData',
            },
            {
                title: 'UI Settings',
                columnSpan: 2,
                key: 'uiSettings',
            },
        ],
        [],
    );

    return (
        <TableContext.Provider value={{ config: tableConfig, configColumnGroups, sortConfig }}>
            {children}
        </TableContext.Provider>
    );
};

export default CountrySettingsTableConfig;
