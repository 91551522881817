import { TableCellProps } from '@mui/material';
import { FC, ReactNode, CSSProperties, Key } from 'react';

export interface iTableConfigItem {
    title: ReactNode;
    key: Key;
    Value: FC<{ index: number }>;
    sortingColName?: string;
    align?: TableCellProps['align'];
    width?: string;
    styles?: CSSProperties;
    getAttrs?: (index: number) => unknown;
}

export interface iTableConfigColumnGroups {
    title: ReactNode;
    columnSpan: number;
    key: Key;
}

export type Sort = {
    field: string;
    order: SortOrder;
};

export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC',
}

export const DEFAULT_ALIGN: TableCellProps['align'] = 'center';
