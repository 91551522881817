import { ReactNode } from 'react';
import cn from 'classnames';

import { FCX } from '@models';

export const Header: FCX<{
    rightContent?: ReactNode;
}> = ({
    className,
    rightContent,
    children,
    style
}) => {
    return (
        <div className={cn('Header', className)} style={style}>
            <div className={'Header__inner'}>
                {children}
                <div className={'Header__right'}>{rightContent}</div>
            </div>
        </div>
    );
};
