import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { NotificationsProvider } from '@toolpad/core/useNotifications';

import Router from '@core/routes';
import AxiosInterceptor from '@core/api/AxiosInterceptors';

import theme from './styles/mui-theme';
import './App.scss';
import { AppProvider } from './App.context';

function App() {
    return (
        <BrowserRouter>
            <AxiosInterceptor>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <AppProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <NotificationsProvider>
                                <Router />
                            </NotificationsProvider>
                        </LocalizationProvider>
                    </AppProvider>
                </ThemeProvider>
            </AxiosInterceptor>
        </BrowserRouter>
    );
}

export default App;
