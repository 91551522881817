import { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FCX } from '@core/models';
import { ApiTableExportFilter } from '@core/utils/file';

export type ExportPopupOptions = Pick<ApiTableExportFilter, 'startDate' | 'endDate' | 'isAll'>;

const RADIO_OPTIONS = [
    {
        value: 'all',
        label: 'Export All',
    },
    {
        value: 'date-range',
        label: 'Export by Date Range',
    },
];

const ExportPopup: FCX<{
    open: boolean;
    handleClose: () => void;
    exportOptionsCallback: (_options: ExportPopupOptions) => void;
}> = ({
    open,
    handleClose,
    exportOptionsCallback
}) => {
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [selectedOption, setSelectedValue] = useState(RADIO_OPTIONS[0].value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const isExportDisabled = useMemo(() => {
        if (selectedOption === 'date-range') {
            return !(startDate && endDate && !startDate.isAfter(endDate));
        }
        return false;
    }, [selectedOption, startDate, endDate]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className="ExportPopup"
            classes={{
                paper: 'ExportPopup__container',
            }}
        >
            <DialogTitle>Please select options for Items Export:</DialogTitle>
            <DialogContent>
                <div className="ExportPopup__content">
                    <div className="ExportPopup__radio-group">
                        {RADIO_OPTIONS.map((option) => (
                            <div className="ExportPopup__radio" key={option.value}>
                                <Radio
                                    checked={selectedOption === option.value}
                                    onChange={handleChange}
                                    value={option.value}
                                    name="radio-buttons"
                                />
                                {option.label}
                            </div>
                        ))}
                    </div>
                    <DatePicker
                        label="Start Date"
                        disabled={selectedOption === 'all'}
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                    />
                    <DatePicker
                        label="End Date"
                        disabled={selectedOption === 'all'}
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        minDate={startDate || undefined}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    disabled={isExportDisabled}
                    onClick={() => {
                        if (selectedOption === 'all') {
                            return exportOptionsCallback({ isAll: true });
                        }

                        exportOptionsCallback({
                            startDate: startDate?.toISOString(),
                            endDate: endDate?.toISOString(),
                        });
                    }}
                >
                    Export
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportPopup;
