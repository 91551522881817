import { FCX } from '@core/models';
import { components } from '@core/api/api';

type KeyMetricAverages = components['schemas']['KeyMetricAverages'];

function addPercentSign(value?: number | null) {
    return value ? `${value}%` : '—';
}

const ClosedSurveyDetails: FCX<{
    keyMetricAverages?: KeyMetricAverages;
}> = ({ keyMetricAverages }) => {
    if (!keyMetricAverages) {
        return null;
    }

    return (
        <>
            <div className="SurveyDetails__spacer"></div>
            <div className="SurveyDetails__param-column">
                <div className="SurveyDetails__section-title">Key metric averages</div>
                <div className="SurveyDetails__param-title">avg unbranded PI:</div>
                <div className="SurveyDetails__param-title">avg branded PI:</div>
                <div className="SurveyDetails__param-title">avg uniqueness:</div>
                <div className="SurveyDetails__param-title">avg frequency:</div>
                <div className="SurveyDetails__param-title">avg draw:</div>
                <div className="SurveyDetails__param-title">avg value:</div>
            </div>
            <div className="SurveyDetails__param-value-column ">
                <div className="SurveyDetails__param-value right-align">
                    {addPercentSign(keyMetricAverages?.unbrandedPi)}
                </div>
                <div className="SurveyDetails__param-value right-align">
                    {addPercentSign(keyMetricAverages?.brandedPi)}
                </div>
                <div className="SurveyDetails__param-value right-align">
                    {addPercentSign(keyMetricAverages?.uniqueness)}
                </div>
                <div className="SurveyDetails__param-value right-align">
                    {addPercentSign(keyMetricAverages?.frequency)}
                </div>
                <div className="SurveyDetails__param-value right-align">{addPercentSign(keyMetricAverages?.draw)}</div>
                <div className="SurveyDetails__param-value right-align">{addPercentSign(keyMetricAverages?.value)}</div>
            </div>
        </>
    );
};

export default ClosedSurveyDetails;
