import { useCallback } from 'react';

import { MethodsEnum } from '@core/models';
import useAxios from '@core/hooks/useFetch';
import { ApiTableExportFilter, downloadExport } from '@core/utils/file';

const POST_EXPORT_CHAIN_DETAILS = '/api/ChainDetails/XlsExport';

export function useExportChainDetailsXls() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<never, ApiTableExportFilter>({
        url: POST_EXPORT_CHAIN_DETAILS,
        method: MethodsEnum.POST,
        lazy: true,
        initialConfig: {
            maxBodyLength: Infinity,
            responseType: 'arraybuffer',
        },
    });

    const doFetchAndDownload = useCallback(
        (filters: ApiTableExportFilter) => {
            doFetch({
                data: filters,
            }).then((resp) => {
                downloadExport(resp, 'Chain_items.xlsx');
            });
        },
        [doFetch],
    );

    return { data, isLoading, error, doFetchAndDownload } as const;
}
