import { FC } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import Layout from '@vms/Layout';
import { Route } from '@core/routes/Route';

import Surveys from '@pages/Surveys';
import ChainItems from '@pages/ChainItems';
import SurveyDetails from '@pages/SurveyDetails';
import SurveyAddItems from '@pages/SurveyAddItems';
import CountrySettings from '@pages/CountrySettings';
import PublicItemsLocker from '@pages/PublicItemsLocker';

const routes = [
    { path: '*', element: <Navigate to={Route.CountrySettings} /> },
    { path: '/', element: <Navigate to={Route.CountrySettings} /> },
    { path: Route.CountrySettings, element: <CountrySettings /> },
    { path: Route.PublicItemsLocker, element: <PublicItemsLocker /> },
    { path: Route.ChainItems, element: <ChainItems /> },
    { path: Route.Surveys, element: <Surveys /> },
    { path: Route.SurveyDetails, element: <SurveyDetails /> },
    { path: Route.SurveyAddItems, element: <SurveyAddItems /> },
];

const Router: FC = () => {
    const routing = useRoutes([
        {
            path: '/',
            element: <Layout />,
            children: routes,
        },
    ]);

    return <>{routing}</>;
};

export default Router;
