import { FCX } from '@core/models';
import { TextInput } from '@components/Inputs';
import { UseFormReturn } from '@core/hooks/useForm';
import { BodySaveSurvey } from '@core/api/useSurveys';
import { TextInputType } from '@components/Inputs/TextInput/TextInput';

const DraftSurveyDetails: FCX<{
    totalSurveySample?: number;
    form: UseFormReturn<BodySaveSurvey>;
}> = ({
    totalSurveySample,
    form: {
        state,
        actions
    }
}) => {
    if (!state?.itemsPerRespondent) {
        return null;
    }

    return (
        <>
            <div className="SurveyDetails__spacer"></div>
            <div className="SurveyDetails__param-column">
                <div className="SurveyDetails__section-title">Per Respondent</div>
                <div className="SurveyDetails__param-title">express items:</div>
                <div className="SurveyDetails__param-title">full concepts:</div>
                <div className="SurveyDetails__param-title">public items:</div>
                <div className="SurveyDetails__param-title">scores plus items:</div>
                <div className="SurveyDetails__param-title">scores enterprise items:</div>
                <div className="SurveyDetails__param-title uppercase">total survey SAMPLE:</div>
            </div>
            <div className="SurveyDetails__param-value-column small-gap">
                <div className="SurveyDetails__param-value text-input">
                    <TextInput
                        type={TextInputType.NUMBER}
                        value={`${state?.itemsPerRespondent?.express ?? ''}`}
                        setValue={(v) => actions.setNestedField('itemsPerRespondent.express', v)}
                        isSmall
                    />
                </div>
                <div className="SurveyDetails__param-value text-input">
                    <TextInput
                        type={TextInputType.NUMBER}
                        value={`${state?.itemsPerRespondent?.conceptLocker ?? ''}`}
                        setValue={(v) => actions.setNestedField('itemsPerRespondent.conceptLocker', v)}
                        isSmall
                    />
                </div>
                <div className="SurveyDetails__param-value text-input">
                    <TextInput
                        type={TextInputType.NUMBER}
                        value={`${state?.itemsPerRespondent?.regular ?? ''}`}
                        setValue={(v) => actions.setNestedField('itemsPerRespondent.regular', v)}
                        isSmall
                    />
                </div>
                <div className="SurveyDetails__param-value text-input">
                    <TextInput
                        type={TextInputType.NUMBER}
                        value={`${state?.itemsPerRespondent?.scoresPlus ?? ''}`}
                        setValue={(v) => actions.setNestedField('itemsPerRespondent.scoresPlus', v)}
                        isSmall
                    />
                </div>
                <div className="SurveyDetails__param-value text-input">
                    <TextInput
                        type={TextInputType.NUMBER}
                        value={`${state?.itemsPerRespondent?.scoresEnterprise ?? ''}`}
                        setValue={(v) => actions.setNestedField('itemsPerRespondent.scoresEnterprise', v)}
                        isSmall
                    />
                </div>
                <div className="SurveyDetails__param-value right-align" style={{ marginTop: '3px' }}>
                    {totalSurveySample ?? '—'}
                </div>
            </div>
        </>
    );
};

export default DraftSurveyDetails;
