import { useMemo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import cn from 'classnames';

import { FCX, iOption } from '@core/models';

const Select: FCX<{
    value: string;
    setValue: (_value: string) => void;
    options: iOption[];
    isDisabled?: boolean;
    label?: string;
}> = ({
    id,
    value,
    setValue,
    options,
    isDisabled = false,
    label,
    className
}) => {
    const renderOptions = useMemo(() => {
        return options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.title}
            </MenuItem>
        ));
    }, [options]);

    const renderSelect = useMemo(
        () => (
            <MuiSelect
                id={id}
                name={id}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                labelId={label}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
                className="Select"
                classes={{
                    root: 'Select__root',
                    outlined: cn('Select__outlined', className),
                }}
                disabled={isDisabled}
            >
                {renderOptions}
            </MuiSelect>
        ),
        [className, id, isDisabled, label, renderOptions, setValue, value],
    );

    if (label) {
        return (
            <FormControl fullWidth variant="standard">
                <InputLabel id={label}>{label}</InputLabel>
                {renderSelect}
            </FormControl>
        );
    }

    return renderSelect;
};

export default Select;
