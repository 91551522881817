import { useReducer, useCallback } from 'react';

export enum FormActionsEnum {
    SET_FIELD = 'SET_FIELD',
    SET_FIELDS = 'SET_FIELDS',
    SET_NESTED_FIELD = 'SET_NESTED_FIELD',
    RESET_FIELDS = 'RESET_FIELDS',
}

export type ReducerAction<F> = {
    type: FormActionsEnum;
    fieldName?: keyof F;
    fieldValue?: F[keyof F];
    fields?: F;
};

export type UseFormReturn<F> = {
    state: F;
    actions: {
        setFields: (fields: F) => void;
        setField: <T extends F[keyof F]>(fieldName: keyof F, fieldValue: T) => void;
        setNestedField: (fieldName: string, fieldValue: F[keyof F]) => void;
        resetForm: () => void;
    };
};

function formReducer<S extends Record<string, unknown>>(state: S, action: ReducerAction<S>): S {
    switch (action.type) {
        case FormActionsEnum.RESET_FIELDS:
            if (action?.fields) {
                return {
                    ...action.fields,
                };
            }

            return state;

        case FormActionsEnum.SET_FIELDS:
            return {
                ...state,
                ...action.fields,
            };

        case FormActionsEnum.SET_FIELD:
            if (!action.fieldName) {
                return state;
            }
            return {
                ...state,
                [action.fieldName]: action.fieldValue,
            };

        case FormActionsEnum.SET_NESTED_FIELD: {
            if (typeof action.fieldName !== 'string') {
                return state;
            }
            const [parent, child] = action.fieldName.split('.');

            return {
                ...state,
                [parent]: {
                    ...(state[parent] && typeof state[parent] === 'object' && !Array.isArray(state[parent])
                        ? (state[parent] as Record<string, unknown>)
                        : {}),
                    [child]: action.fieldValue,
                },
            };
        }

        default:
            return state;
    }
}

export default function useForm<F extends Record<string, unknown>>(defaultState: F): UseFormReturn<F> {
    const [state, dispatch] = useReducer<React.Reducer<F, ReducerAction<F>>>(formReducer, defaultState);

    const setFields = useCallback((fields: F) => {
        dispatch({ type: FormActionsEnum.SET_FIELDS, fields });
    }, []);

    const setField = useCallback(<T extends F[keyof F]>(fieldName: keyof F, fieldValue: T) => {
        dispatch({ type: FormActionsEnum.SET_FIELD, fieldName, fieldValue });
    }, []);

    const setNestedField = useCallback((fieldName: string, fieldValue: F[keyof F]) => {
        dispatch({ type: FormActionsEnum.SET_NESTED_FIELD, fieldName, fieldValue });
    }, []);

    const resetForm = useCallback(() => {
        dispatch({ type: FormActionsEnum.RESET_FIELDS, fields: defaultState });
    }, [defaultState]);

    return {
        state,
        actions: {
            setFields,
            setField,
            setNestedField,
            resetForm,
        },
    };
}
