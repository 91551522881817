import { useCallback } from 'react';
import { useNotifications } from '@toolpad/core/useNotifications';

import Dialog from '@mui/material/Dialog';
import MuiButton from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { FCX } from '@core/models';
import Button from '@components/Button';
import { CloseSurveyParams, useCloseSurvey } from '@core/api/useSurveys';

const CloseSurveyPopup: FCX<{
    open: boolean;
    onCloseSurveyCallback: () => void;
    handleClosePopup: () => void;
    surveyId?: number;
}> = ({
    open,
    onCloseSurveyCallback,
    handleClosePopup,
    surveyId
}) => {
    const { doFetch } = useCloseSurvey();
    const notifications = useNotifications();

    const onCloseSurvey = useCallback(() => {
        const params: CloseSurveyParams = {
            surveyId: surveyId,
        };

        doFetch({
            params,
        })
            .then(() => {
                notifications.show('Survey closed successfully', {
                    severity: 'success',
                    autoHideDuration: 4500,
                });
                onCloseSurveyCallback();
            })
            .catch(() => {
                notifications.show('Survey close failed', {
                    severity: 'error',
                    autoHideDuration: 4500,
                });
            })
            .finally(() => {
                handleClosePopup();
            });
    }, [doFetch, handleClosePopup, notifications, onCloseSurveyCallback, surveyId]);

    return (
        <Dialog open={open} onClose={() => handleClosePopup()}>
            <DialogTitle>{'Close'}</DialogTitle>
            <DialogContent dividers>Are you sure you want to close this survey?</DialogContent>
            <DialogActions>
                <MuiButton color="inherit" onClick={handleClosePopup}>
                    Cancel
                </MuiButton>
                <Button onClick={onCloseSurvey}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CloseSurveyPopup;
