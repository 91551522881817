import { createContext, useContext } from 'react';

import { useTableSortReturn } from '@core/hooks/useTableSort';

import { iTableConfigColumnGroups, iTableConfigItem } from './TableConfig';

type iTableContext = {
    config: iTableConfigItem[];
    configColumnGroups?: iTableConfigColumnGroups[];
    sortConfig?: useTableSortReturn<never>['sortConfig'];
};

export const TableContext = createContext<iTableContext | undefined>(undefined);

export function useTableContext() {
    const ctx = useContext(TableContext);

    if (ctx === undefined) throw Error('Table context can\'t be used outside of the TableContext.Provider');

    return ctx;
}
