import { ReactElement, useCallback, useMemo } from 'react';
import { IconButton } from '@mui/material';

import { FCX, ID } from '@models';
import { IconType } from '@svg/index';
import useTableSort from '@core/hooks/useTableSort';
import { SurveyDetailsItem } from '@core/api/useSurveys';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { formatBoolean, formatDefaultDate } from '@core/utils/string';

const AddItemsTableConfig: FCX<{
    data: SurveyDetailsItem[];
    actionIcon: ReactElement<IconType>;
    actionCallback: (_i: SurveyDetailsItem) => void;
    newIncludedItemsIds?: ID[];
}> = ({
    actionIcon,
    actionCallback,
    children,
    data,
    newIncludedItemsIds = []
}) => {
    const {
        sortedResults,
        sortConfig
    } = useTableSort(data);

    const getIncludedItemClassname = useCallback(
        (id?: ID) => (newIncludedItemsIds.includes(id ?? '') ? 'SurveyAddItems__shaded-cell' : ''),
        [newIncludedItemsIds],
    );

    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'id',
                sortingColName: 'id',
                title: 'ID',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {sortedResults?.[index]?.id}
                    </div>
                ),
                width: '50px',
            },
            {
                key: 'date',
                sortingColName: 'date',
                title: 'Date',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {formatDefaultDate(sortedResults?.[index]?.date) || 'N/A'}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'testType',
                sortingColName: 'testType',
                title: 'Test Type',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {sortedResults?.[index]?.testType}
                    </div>
                ),
                width: '90px',
            },
            {
                key: 'companyTestedBy',
                sortingColName: 'companyName',
                title: 'Company (Tested By)',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {sortedResults?.[index]?.companyName}
                    </div>
                ),
                width: '80px',
            },
            {
                key: 'chainSegmentTestedFor',
                sortingColName: 'testedFor',
                title: 'Chain/Segment (Tested For)',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {sortedResults?.[index]?.testedFor}
                    </div>
                ),
                width: '90px',
            },
            {
                key: 'itemType',
                sortingColName: 'itemType',
                title: 'Item Type',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {sortedResults?.[index]?.itemType}
                    </div>
                ),
                width: '90px',
            },
            {
                key: 'itemName',
                sortingColName: 'itemName',
                title: 'Item Name',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {sortedResults?.[index]?.itemName}
                    </div>
                ),
                width: '160px',
            },
            {
                key: 'price',
                title: 'Price',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {sortedResults?.[index]?.price}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'image',
                sortingColName: 'isPhoto',
                title: 'Image',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {formatBoolean(sortedResults?.[index]?.isPhoto)}
                    </div>
                ),
                width: '50px',
            },
            {
                key: 'exclusive',
                sortingColName: 'exclusive',
                title: 'Exclusive',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {formatBoolean(sortedResults?.[index]?.exclusive)}
                    </div>
                ),
                width: '50px',
            },
            {
                key: 'sampleSize',
                title: 'Sample Size',
                Value: ({ index }) => (
                    <div
                        className={getIncludedItemClassname(sortedResults?.[index]?.id)}
                    >{`${sortedResults?.[index]?.currentSampleSize} / ${sortedResults?.[index]?.neededSampleSize}`}</div>
                ),
                width: '90px',
            },
            {
                key: 'priority',
                sortingColName: 'priority',
                title: 'Priority',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        {sortedResults?.[index]?.priority ?? '—'}
                    </div>
                ),
                width: '60px',
            },
            {
                key: 'action',
                title: '',
                Value: ({ index }) => (
                    <div className={getIncludedItemClassname(sortedResults?.[index]?.id)}>
                        <IconButton onClick={() => actionCallback(sortedResults?.[index])}>{actionIcon}</IconButton>
                    </div>
                ),
                width: '40px',
            },
        ],
        [actionCallback, actionIcon, getIncludedItemClassname, sortedResults],
    );

    return <TableContext.Provider value={{ config: tableConfig, sortConfig }}>{children}</TableContext.Provider>;
};

export default AddItemsTableConfig;
