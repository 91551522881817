import { useMemo } from 'react';
import { Checkbox, IconButton } from '@mui/material';

import { FCX } from '@models';
import { IconTrash } from '@svg/index';
import useTableSort from '@core/hooks/useTableSort';
import { SurveyDetailsItem } from '@core/api/useSurveys';
import { TableContext } from '@components/Table/TableContext';
import { SurveyStatusIdEnum } from '@core/hooks/useCommonEnums';
import { formatBoolean, formatDefaultDate } from '@core/utils/string';
import { iTableConfigColumnGroups, iTableConfigItem } from '@components/Table/TableConfig';

import DesiredSampleInput from './components/DesiredSampleInput';

const SurveyDetailsTableConfig: FCX<{
    data: SurveyDetailsItem[];
    surveyStatus?: number;
    onDeleteItem: (_id?: number) => void;
}> = ({
    data,
    children,
    surveyStatus,
    onDeleteItem
}) => {
    const {
        sortedResults,
        sortConfig
    } = useTableSort(data);

    const customColumns: iTableConfigItem[] = useMemo(() => {
        if (surveyStatus === SurveyStatusIdEnum.Draft) {
            return [
                {
                    key: 'desiredSample',
                    title: 'Desired Sample',
                    Value: ({ index }) => (
                        <DesiredSampleInput
                            onChange={(value) => {
                                console.log('Desired Sample', value);
                            }}
                            testType={sortedResults?.[index]?.testType ?? ''}
                        />
                    ),
                    width: '90px',
                },
                {
                    key: 'priority',
                    sortingColName: 'priority',
                    title: 'Priority',
                    Value: ({ index }) => <>{sortedResults?.[index]?.priority ?? '—'}</>,
                    width: '70px',
                },
                {
                    key: 'action',
                    title: <IconTrash color="#FFA533" />,
                    Value: ({ index }) => (
                        <IconButton
                            onClick={() => onDeleteItem(sortedResults?.[index]?.id)}
                            sx={{ position: 'relative', left: '4px' }}
                        >
                            <IconTrash color="#FFA533" />
                        </IconButton>
                    ),
                    width: '30px',
                },
            ];
        }

        if (surveyStatus === SurveyStatusIdEnum.Closed) {
            return [
                {
                    key: 'sampleSize',
                    title: 'Sample Size',
                    Value: ({ index }) => (
                        <>{`${sortedResults?.[index]?.currentSampleSize} / ${sortedResults?.[index]?.neededSampleSize}`}</>
                    ),
                    width: '90px',
                },
                {
                    key: 'priority',
                    sortingColName: 'priority',
                    title: 'Priority',
                    Value: ({ index }) => <>{sortedResults?.[index]?.priority ?? '—'}</>,
                    width: '70px',
                },
                {
                    key: 'publish',
                    title: 'Publish',
                    Value: ({ index }) => <Checkbox checked={sortedResults?.[index]?.isPublished} color="success" />,
                    width: '50px',
                },
            ];
        }

        return [
            {
                key: 'sampleSize',
                title: 'Sample Size',
                Value: ({ index }) => (
                    <>{`${sortedResults?.[index]?.currentSampleSize} / ${sortedResults?.[index]?.neededSampleSize}`}</>
                ),
                width: '90px',
            },
            {
                key: 'priority',
                sortingColName: 'priority',
                title: 'Priority',
                Value: ({ index }) => <>{sortedResults?.[index]?.priority ?? '—'}</>,
                width: '70px',
            },
            {
                key: 'close',
                title: 'Close',
                Value: ({ index }) => <Checkbox checked={!sortedResults?.[index]?.isClosed} color="success" />,
                width: '50px',
            },
            {
                key: 'publish',
                title: 'Publish',
                Value: ({ index }) => <Checkbox checked={sortedResults?.[index]?.isPublished} color="success" />,
                width: '50px',
            },
        ];
    }, [onDeleteItem, sortedResults, surveyStatus]);

    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'id',
                sortingColName: 'id',
                title: 'ID',
                Value: ({ index }) => <>{sortedResults?.[index]?.id}</>,
                width: '50px',
            },
            {
                key: 'date',
                sortingColName: 'date',
                title: 'Date',
                Value: ({ index }) => <>{formatDefaultDate(sortedResults?.[index]?.date) || 'N/A'}</>,
                width: '70px',
            },
            {
                key: 'testType',
                sortingColName: 'testType',
                title: 'Test Type',
                width: '90px',
                Value: ({ index }) => <>{sortedResults?.[index]?.testType}</>,
            },
            {
                key: 'companyTestedBy',
                sortingColName: 'companyName',
                title: 'Company (Tested By)',
                Value: ({ index }) => <>{sortedResults?.[index]?.companyName}</>,
                width: '80px',
            },
            {
                key: 'chainSegmentTestedFor',
                sortingColName: 'testedFor',
                title: 'Chain/Segment (Tested For)',
                Value: ({ index }) => <>{sortedResults?.[index]?.testedFor}</>,
                width: '90px',
            },
            {
                key: 'itemType',
                sortingColName: 'itemType',
                title: 'Item Type',
                Value: ({ index }) => <>{sortedResults?.[index]?.itemType}</>,
                width: '90px',
            },
            {
                key: 'itemName',
                sortingColName: 'itemName',
                title: 'Item Name',
                Value: ({ index }) => <>{sortedResults?.[index]?.itemName}</>,
                width: '160px',
            },
            {
                key: 'price',
                title: 'Price',
                Value: ({ index }) => <>{sortedResults?.[index]?.price}</>,
                width: '70px',
            },
            {
                key: 'image',
                sortingColName: 'isPhoto',
                title: 'Image',
                Value: ({ index }) => <>{formatBoolean(sortedResults?.[index]?.isPhoto)}</>,
                width: '50px',
            },
            {
                key: 'exclusive',
                sortingColName: 'exclusive',
                title: 'Exclusive',
                Value: ({ index }) => <>{formatBoolean(sortedResults?.[index]?.exclusive)}</>,
                width: '50px',
            },
            ...customColumns,
        ],
        [customColumns, sortedResults],
    );

    const configColumnGroups = useMemo((): iTableConfigColumnGroups[] => {
        if (surveyStatus === SurveyStatusIdEnum.Draft) {
            return [
                {
                    title: 'User Settings',
                    columnSpan: 10,
                    key: 'userSettings',
                },
                {
                    title: 'Admin Settings',
                    columnSpan: 2,
                    key: 'adminSettings',
                },
                {
                    title: '',
                    columnSpan: 1,
                    key: 'actions',
                },
            ];
        }

        return [
            {
                title: 'User Settings',
                columnSpan: 10,
                key: 'userSettings',
            },
            {
                title: 'Internal',
                columnSpan: surveyStatus === SurveyStatusIdEnum.Closed ? 3 : 4,
                key: 'internal',
            },
        ];
    }, [surveyStatus]);

    return (
        <TableContext.Provider value={{ config: tableConfig, configColumnGroups, sortConfig }}>
            {children}
        </TableContext.Provider>
    );
};

export default SurveyDetailsTableConfig;
