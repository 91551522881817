import MuiTableRow from '@mui/material/TableRow';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

import TableCell from './TableCell';
import { useTableContext } from './TableContext';
import { DEFAULT_ALIGN, SortOrder } from './TableConfig';

const TableHeadRow: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const {
        config,
        sortConfig
    } = useTableContext();

    return (
        <MuiTableRow classes={{ head: 'Table__head-row' }}>
            {config.map((column, colIndex) => {
                const isSortable = !!column.sortingColName;
                const isSorted = sortConfig?.sortedColumn?.field === column?.sortingColName;

                return (
                    <TableCell
                        key={colIndex}
                        align={column.align ?? DEFAULT_ALIGN}
                        onClick={() => {
                            if (isSortable) {
                                sortConfig?.onSort(column.sortingColName as string);
                            }
                        }}
                        styles={{
                            ...column.styles,
                            ...(column.width ? { minWidth: column.width, maxWidth: column.width } : {}),

                            cursor: isSortable ? 'pointer' : 'default',
                        }}
                    >
                        {column.title}
                        {isSortable && (
                            <div className="Table__head-row-sort">
                                {isSorted && sortConfig?.sortedColumn?.order === SortOrder.Asc && <NorthIcon />}
                                {isSorted && sortConfig?.sortedColumn?.order === SortOrder.Desc && <SouthIcon />}
                            </div>
                        )}
                    </TableCell>
                );
            })}
            {children}
        </MuiTableRow>
    );
};

export default TableHeadRow;
