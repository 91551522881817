import { useMemo } from 'react';

import { components } from '@core/api/api';
import { Select } from '@components/Inputs/Select';
import { UseFormReturn } from '@core/hooks/useForm';
import { BodySaveSurvey } from '@core/api/useSurveys';
import { FCX, IdNameModel, iOption } from '@core/models';
import { SurveyStatusIdEnum } from '@core/hooks/useCommonEnums';

const CustomFielding: FCX<{
    customFieldingOptions: IdNameModel[];
    surveyStatusId: components['schemas']['SurveyDetails']['status'];
    form: UseFormReturn<BodySaveSurvey>;
}> = ({
    customFieldingOptions,
    surveyStatusId,
    form: {
        state,
        actions
    }
}) => {
    const options: iOption[] = useMemo(
        () => customFieldingOptions?.map((cf) => ({ id: cf?.id ?? '', title: cf?.name ?? '' })) ?? [],
        [customFieldingOptions],
    );

    if (SurveyStatusIdEnum.Draft === surveyStatusId) {
        return (
            <div className="SurveyDetails__select-container">
                <Select
                    options={options}
                    value={String(state?.customFielding ?? '')}
                    setValue={(v) => actions?.setField('customFielding', v)}
                    className="small"
                />
            </div>
        );
    }

    return <div className="SurveyDetails__param-value">{state?.customFielding || '- - -'}</div>;
};

export default CustomFielding;
