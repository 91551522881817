import MuiTableRow from '@mui/material/TableRow';

import TableCell from './TableCell';
import { useTableContext } from './TableContext';
import { DEFAULT_ALIGN } from './TableConfig';

const TableHeadGroups: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const { configColumnGroups } = useTableContext();

    if (!configColumnGroups) {
        return null;
    }

    return (
        <MuiTableRow classes={{ head: 'Table__head-groups' }}>
            {configColumnGroups.map((group, groupIndex) => {
                return (
                    <TableCell key={groupIndex} align={DEFAULT_ALIGN} colSpan={group.columnSpan}>
                        {group.title}
                    </TableCell>
                );
            })}
            {children}
        </MuiTableRow>
    );
};

export default TableHeadGroups;
