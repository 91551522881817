import { ChangeEventHandler } from 'react';
import Button from '@mui/material/Button';
import { useNotifications } from '@toolpad/core/useNotifications';

import { FCX } from '@core/models';
import { useBulkImport } from '@core/api/usePublicItemsLockerFile';

const BulkImport: FCX = () => {
    const notifications = useNotifications();

    const { doFetch } = useBulkImport();

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
        const fileList = event.target.files as FileList;
        if (!fileList) {
            return;
        }

        try {
            await doFetch({ data: { formFile: fileList?.[0] as unknown as string } }).then((res) => {
                if (res?.data?.length === 0) {
                    return notifications.show('Bulk import was successful', {
                        severity: 'success',
                        autoHideDuration: 4500,
                    });
                }

                notifications.show('Bulk import failed', { severity: 'error', autoHideDuration: 4500 });
            });
        } catch (error) {
            notifications.show('Bulk import failed', { severity: 'error', autoHideDuration: 4500 });
        }
    };

    return (
        <div className="BulkImports">
            <Button component="label" role={undefined} tabIndex={-1} className="BulkImports__button">
                Bulk Import
                <input
                    className="PublicItemsLocker__hidden-input"
                    type="file"
                    accept="text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleFileChange}
                />
            </Button>
        </div>
    );
};

export default BulkImport;
