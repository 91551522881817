import useAxios from '@core/hooks/useFetch';
import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@core/models';

import { paths } from './api';

const POST_SURVEY_AVAILABLE_ITEMS = '/api/Survey/GetAvailableItems';
const POST_SAVE_SURVEY_ITEMS = '/api/Survey/SaveSurveyItems';

type PostSurveyAvailableItems = paths[typeof POST_SURVEY_AVAILABLE_ITEMS][MethodsEnum.POST];
export type PostSurveyAvailableItemsResponse =
    PostSurveyAvailableItems[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];
export type BodySurveyAvailableItems = NonNullable<
    PostSurveyAvailableItems[OperationsEnum.REQUEST]
>[Content][ContentTypeEnum.Json];

export function useSurveyAvailableItems() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<PostSurveyAvailableItemsResponse, BodySurveyAvailableItems>({
        url: POST_SURVEY_AVAILABLE_ITEMS,
        method: MethodsEnum.POST,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}

type PostSaveSurveyItems = paths[typeof POST_SAVE_SURVEY_ITEMS][MethodsEnum.POST];
export type BodySaveSurveyItems = NonNullable<
    PostSaveSurveyItems[OperationsEnum.REQUEST]
>[Content][ContentTypeEnum.Json];

export function useSaveSurveyItems() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<never, BodySaveSurveyItems>({
        url: POST_SAVE_SURVEY_ITEMS,
        method: MethodsEnum.POST,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}
