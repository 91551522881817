import { Header } from '@vms/Header';
import Button from '@components/Button';
import { Table } from '@components/Table';
import ExportPopup from '@vms/ExportPopup';
import { TextInput } from '@components/Inputs';
import { Select } from '@components/Inputs/Select';
import { TextInputType } from '@components/Inputs/TextInput/TextInput';

import BulkImport from './BulkImports';
import usePublicItemsLocker from './usePublicItemsLocker';
import PublicItemsLockerTableConfig from './PublicItemsLockerTableConfig';

const PublicItemsLocker = () => {
    const {
        country,
        chains,
        search,
        exportXls
    } = usePublicItemsLocker();

    return (
        <div className="PublicItemsLocker">
            <Header>Public Items Locker</Header>

            <div className="PublicItemsLocker__actions">
                <div className="PublicItemsLocker__actions-slot">
                    <div className="PublicItemsLocker__country-select">
                        <Select
                            options={country.countryOptions}
                            value={country.selectedCountryId}
                            setValue={country.setSelectedCountryId}
                        />
                    </div>
                </div>
                <div className="PublicItemsLocker__actions-slot">
                    Chain Unit Minimum
                    <div className="PublicItemsLocker__chain-unit-input">
                        <TextInput
                            type={TextInputType.NUMBER}
                            value={chains.chainUnitsInput}
                            setValue={chains.setChainUnitsInput}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    chains.onUpdateChainUnitMinimum();
                                }
                            }}
                        />
                    </div>
                    <Button modifiers={['h-tall']} onClick={chains.onUpdateChainUnitMinimum}>
                        Update
                    </Button>
                </div>
                <div className="PublicItemsLocker__actions-slot">
                    <BulkImport />
                    <Button
                        modifiers={['h-tall']}
                        onClick={() => {
                            exportXls.setIsExportPopupOpen(true);
                        }}
                    >
                        XLS Export
                    </Button>
                    <TextInput value={search.searchValue} setValue={search.setSearchValue} placeholder="Search chain" />
                </div>
            </div>

            <ExportPopup
                open={exportXls.isExportPopupOpen}
                handleClose={() => exportXls.setIsExportPopupOpen(false)}
                exportOptionsCallback={exportXls.onExportPublicItems}
            />

            <PublicItemsLockerTableConfig
                data={search.results}
                countryName={country.countryName}
                onToggleChainActiveStatus={chains.onToggleChainActiveStatus}
            >
                <Table rowsCount={search.results.length} isLoading={search.isLoading} />
            </PublicItemsLockerTableConfig>
        </div>
    );
};

export default PublicItemsLocker;
