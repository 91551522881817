import { MouseEvent } from 'react';
import { FCX } from '@models';
import { Link, NavLink, To } from 'react-router-dom';
import cn from 'classnames';

export type ButtonModifiers = 'rounded' | 'green' | 'h-tall' | 'naked';

export enum ButtonType {
    Button,
    Link,
    NavLink,
    Anchor,
}

export interface iProps {
    type?: ButtonType;
    modifiers?: ButtonModifiers[];
    onClick?: (event: MouseEvent) => void;
    link?: To;
    state?: unknown;
    isDisabled?: boolean;
}

const Button: FCX<iProps> = ({
    type = ButtonType.Button,
    modifiers = [],
    onClick,
    isDisabled,
    link,
    children,
    className,
    style,
    ...attrs
}) => {
    const props = {
        className: cn(
            className,
            'Button',
            ...modifiers,
            !onClick && !link && 'is-no-click',
            isDisabled && 'is-disabled',
        ),
        onClick,
        style,
        children,
        ...attrs,
    };

    switch (type) {
        case ButtonType.Button: {
            return <button {...props} disabled={isDisabled} />;
        }
        case ButtonType.Link: {
            return <Link to={link || '#'} {...props} />;
        }
        case ButtonType.NavLink: {
            return <NavLink to={link || '#'} {...props} />;
        }
        case ButtonType.Anchor: {
            return (
                <a target="_blank" href={(link as string) || '#'} {...props} rel="noreferrer">
                    {children}
                </a>
            );
        }
        default:
            return null;
    }
};

export default Button;
