import { DatePicker } from '@mui/x-date-pickers';

import { Header } from '@vms/Header';
import Button from '@components/Button';
import { Table } from '@components/Table';
import { TextInput } from '@components/Inputs';
import { IconMoveUp, IconTrash } from '@svg/index';
import useTableQuickSearch from '@core/hooks/useTableQuickSearch';

import useSurveyAddItems from './useSurveyAddItems';
import AddItemsTableConfig from './AddItemsTableConfig';

const datePickerSlotStyles = {
    textField: { InputProps: { slotProps: { input: { sx: { padding: '9px', fontSize: '14px' } } } } },
};

const SurveyAddItems = () => {
    const {
        filters,
        actions,
        data,
        isAvailableItemsLoading
    } = useSurveyAddItems();

    const includedItems = useTableQuickSearch(data.includedItems, (s) => s.itemName);
    const availableItems = useTableQuickSearch(data.availableItems ?? [], (s) => s.itemName);

    return (
        <div className="SurveyAddItems">
            <div className="SurveyAddItems__name">Survey Name: {data?.surveyData?.customName}</div>

            <Header
                style={{ marginBottom: '16px' }}
                rightContent={
                    <div className="SurveyAddItems__search-container">
                        <TextInput
                            value={includedItems.searchValue}
                            setValue={includedItems.setSearchValue}
                            placeholder="Search items by name"
                        />
                    </div>
                }
            >
                <div className="SurveyAddItems__section-title">included items</div>
            </Header>
            <div className="SurveyAddItems__table-container">
                <AddItemsTableConfig
                    data={includedItems.searchResults ?? []}
                    actionCallback={(item) => actions.onRemoveIncludedItem(item)}
                    actionIcon={<IconTrash color="#FFA533" />}
                    newIncludedItemsIds={data.newIncludedItems.flatMap((i) => (i?.id ? [i.id] : []))}
                >
                    <Table rowsCount={includedItems.searchResults?.length ?? 0} isLoading={data?.surveyData === null} />
                </AddItemsTableConfig>
            </div>

            <div className="SurveyAddItems__section-title" style={{ marginBottom: '20px' }}>
                Available items
            </div>
            <Header
                style={{ marginBottom: '16px' }}
                rightContent={
                    <div className="SurveyAddItems__search-container">
                        <TextInput
                            value={availableItems.searchValue}
                            setValue={availableItems.setSearchValue}
                            placeholder="Search available items by name"
                        />
                    </div>
                }
            >
                <div className="SurveyAddItems__filters">
                    Filter By:
                    <DatePicker
                        label="Start Date"
                        value={filters.startDate}
                        onChange={(v) => v && filters.setStartDate(v.startOf('day'))}
                        slotProps={datePickerSlotStyles}
                    />
                    <DatePicker
                        label="End Date"
                        value={filters.endDate}
                        disableFuture
                        onChange={(v) => v && filters.setEndDate(v.endOf('day'))}
                        minDate={filters.startDate || undefined}
                        slotProps={datePickerSlotStyles}
                    />
                </div>
            </Header>
            <div className="SurveyAddItems__table-container">
                <AddItemsTableConfig
                    data={availableItems.searchResults ?? []}
                    actionCallback={(item) => actions.onIncludeNewItem(item)}
                    actionIcon={<IconMoveUp />}
                >
                    <Table rowsCount={availableItems.searchResults?.length ?? 0} isLoading={isAvailableItemsLoading} />
                </AddItemsTableConfig>
            </div>

            <div className="SurveyAddItems__footer">
                <Button modifiers={['naked']} onClick={() => actions.onNavigateToSurveyDetails()}>
                    Cancel
                </Button>
                <Button onClick={() => actions.onSaveIncludedItems()}>Save selections</Button>
            </div>
        </div>
    );
};

export default SurveyAddItems;
