import { useCallback, useState } from 'react';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Header } from '@vms/Header';
import Button from '@components/Button';
import { Table } from '@components/Table';
import { Route } from '@core/routes/Route';
import ExportPopup from '@vms/ExportPopup';
import { TextInput } from '@components/Inputs';
import { IconCaret, IconPlus } from '@svg/index';
import useTableQuickSearch from '@core/hooks/useTableQuickSearch';
import { useExportChainDetailsXls } from '@core/api/useChainDetailsFile';
import { ChainDetailsMenuItem, useDeleteMenuItem, useGetChainDetails } from '@core/api/useChainDetails';

import ChainItemPopup from './ChainItemPopup';
import ChainItemsTableConfig from './ChainItemsTableConfig';

const ChainItems = () => {
    const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
    const [isChainPopupOpen, setIsChainPopupOpen] = useState(false);
    const [popupInitialData, setPopupInitialData] = useState<ChainDetailsMenuItem | undefined>(undefined);

    const navigate = useNavigate();
    const { id: chainId } = useParams();
    const { doFetch: deleteMenuItem } = useDeleteMenuItem();
    const { doFetchAndDownload } = useExportChainDetailsXls();

    const {
        doFetch: getChainDetails,
        data: chainDetailsData,
        isLoading
    } = useGetChainDetails(chainId);

    const {
        searchValue,
        setSearchValue,
        searchResults
    } = useTableQuickSearch(
        chainDetailsData?.items ?? [],
        (item) => item?.name,
    );

    const onAddChainItem = useCallback(() => {
        setIsChainPopupOpen(true);
        setPopupInitialData(undefined);
    }, []);

    const onEditChainItem = useCallback((data: ChainDetailsMenuItem) => {
        setIsChainPopupOpen(true);
        setPopupInitialData(data);
    }, []);

    const handleClose = useCallback(
        (shouldRefreshItems = false) => {
            setIsChainPopupOpen(false);
            setPopupInitialData(undefined);

            if (shouldRefreshItems) {
                getChainDetails();
            }
        },
        [getChainDetails],
    );

    const onDeleteMenuItem = useCallback(
        (itemId?: number) => {
            deleteMenuItem({
                params: { id: itemId },
            }).then(() => getChainDetails());
        },
        [deleteMenuItem, getChainDetails],
    );

    return (
        <>
            <div className="ChainItems">
                <Header
                    rightContent={
                        <div className="ChainItems__actions">
                            <Button modifiers={['h-tall']} onClick={onAddChainItem}>
                                <IconPlus />
                                Add Item
                            </Button>
                            <Button
                                modifiers={['h-tall']}
                                onClick={() => {
                                    setIsExportPopupOpen(true);
                                }}
                            >
                                XLS Export
                            </Button>
                            <TextInput value={searchValue} setValue={setSearchValue} placeholder="Search Items" />
                        </div>
                    }
                >
                    <div className="ChainItems__header">
                        <IconButton
                            onClick={() => {
                                if (window.history?.length && window.history.length > 1) {
                                    navigate(-1);
                                } else {
                                    navigate(Route.PublicItemsLocker);
                                }
                            }}
                        >
                            <IconCaret />
                        </IconButton>
                        {chainDetailsData?.name ?? ''}
                        {chainDetailsData?.smallLogoUrl && (
                            <img className="ChainItems__chain-logo" src={chainDetailsData.smallLogoUrl} />
                        )}
                    </div>
                </Header>

                <ChainItemPopup
                    open={isChainPopupOpen}
                    handleClose={handleClose}
                    initialData={popupInitialData}
                    chainDetailsData={chainDetailsData}
                />
                <ExportPopup
                    open={isExportPopupOpen}
                    handleClose={() => setIsExportPopupOpen(false)}
                    exportOptionsCallback={(options) => {
                        doFetchAndDownload({
                            sipChainId: chainId,
                            ...options,
                        });

                        setIsExportPopupOpen(false);
                    }}
                />

                <ChainItemsTableConfig
                    data={searchResults}
                    onEditChainItem={onEditChainItem}
                    onDeleteMenuItem={onDeleteMenuItem}
                >
                    <Table rowsCount={searchResults.length} isLoading={isLoading} />
                </ChainItemsTableConfig>
            </div>
            {/* Will be used in v2 */}
            {/* <div className="ChainItems__footer">
                <Button modifiers={['h-tall']} onClick={() => console.log('Save & Exit')}>
                    Save & Exit
                </Button>
            </div> */}
        </>
    );
};

export default ChainItems;
