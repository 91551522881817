import { useState, useRef, useEffect } from 'react';
import TruncateMarkup from 'react-truncate-markup';

import { FCX } from '@core/models';

import './LineEllipsis.scss';
import cn from 'classnames';

const LineEllipsis: FCX<{
    lines: number;
    textContent?: string;
    lineHeight?: number;
    rowHeight?: number;
}> = ({
    textContent = '',
    lines,
    rowHeight = 64,
    lineHeight
}) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const expanded = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (expanded.current) {
            setIsTruncated(expanded.current.getBoundingClientRect().height > rowHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded?.current, rowHeight]);

    return (
        <div className="LineEllipsis">
            <TruncateMarkup lines={lines} lineHeight={lineHeight}>
                <div>{textContent}</div>
            </TruncateMarkup>
            <div
                className={cn('LineEllipsis__expanded', {
                    'LineEllipsis__expanded--hidden': !isTruncated,
                })}
                ref={expanded}
            >
                {textContent}
            </div>
        </div>
    );
};

export default LineEllipsis;
