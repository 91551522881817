import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useAxiosReturn } from '@core/hooks/useFetch';
import { GetCommonResponse, useGetCommon } from '@core/api/useCommon';
import { CountrySettingsResponse, useGetCountrySettings } from '@core/api/useCountrySettings';

const DEFAULT_CONTEXT_VALUE = {
    countrySettings: [],
    common: null,
    isLoading: true,
    fetchCountrySettings: undefined,
};

export const AppContext = createContext<{
    countrySettings: CountrySettingsResponse;
    common: GetCommonResponse | null;
    isLoading: boolean;
    fetchCountrySettings?: useAxiosReturn<CountrySettingsResponse, unknown>['doFetch'];
}>(DEFAULT_CONTEXT_VALUE);

export function AppProvider({ children }: { children: ReactNode }) {
    const [countrySettings, setCountrySettings] = useState<CountrySettingsResponse>([]);
    const [common, setCommon] = useState<GetCommonResponse | null>(null);

    const {
        data,
        isLoading,
        doFetch: fetchCountrySettings
    } = useGetCountrySettings();

    const {
        data: commonData,
        isLoading: isCommonLoading
    } = useGetCommon();

    useEffect(() => {
        if (commonData) {
            setCommon(commonData);
        }
    }, [commonData]);

    useEffect(() => {
        if (data) {
            setCountrySettings(data);
        }
    }, [data]);

    return (
        <AppContext.Provider
            value={{ countrySettings, common, isLoading: isLoading || isCommonLoading, fetchCountrySettings }}
        >
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}
