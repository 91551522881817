import { useCallback } from 'react';
import { GetCommonResponse } from '@core/api/useCommon';

import { useAppContext } from '../../App.context';

type CommonTypes = keyof GetCommonResponse;

const useCommonEnums = () => {
    const { common } = useAppContext();

    const findCommonNameById = useCallback(
        (commonType: CommonTypes, id?: number): string | null => {
            const commontype = common?.[commonType]?.find((item) => item.id === id);

            if (!commontype || !commontype.name) {
                return null;
            }

            return commontype.name;
        },
        [common],
    );

    return { findCommonNameById, common };
};

export const customStatusesMap = {
    1: 'Live',
    2: 'Draft',
    3: 'Draft',
};

export enum SurveyStatusIdEnum {
    Draft = 0,
    Live = 1,
    Closed = 2,
}

export default useCommonEnums;
