import { useCallback } from 'react';
import { useNotifications } from '@toolpad/core/useNotifications';

import Dialog from '@mui/material/Dialog';
import MuiButton from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { FCX } from '@core/models';
import Button from '@components/Button';
import { useLaunchSurvey } from '@core/api/useSurveys';

const LaunchSurveyPopup: FCX<{
    open: boolean;
    onLaunchSurveyCallback: () => void;
    handleClosePopup: () => void;
    surveyId?: number;
}> = ({
    open,
    onLaunchSurveyCallback,
    handleClosePopup,
    surveyId
}) => {
    const { doFetch } = useLaunchSurvey(`${surveyId ?? ''}`);
    const notifications = useNotifications();

    const onLaunchSurvey = useCallback(() => {
        doFetch()
            .then(() => {
                notifications.show('Survey launched successfully', {
                    severity: 'success',
                    autoHideDuration: 4500,
                });
                onLaunchSurveyCallback();
            })
            .catch(() => {
                notifications.show('Survey launch failed', {
                    severity: 'error',
                    autoHideDuration: 4500,
                });
            })
            .finally(() => {
                handleClosePopup();
            });
    }, [doFetch, handleClosePopup, notifications, onLaunchSurveyCallback]);

    return (
        <Dialog open={open} onClose={() => handleClosePopup()}>
            <DialogTitle>{'Launch'}</DialogTitle>
            <DialogContent dividers>Are you sure you want to launch this survey?</DialogContent>
            <DialogActions>
                <MuiButton color="inherit" onClick={handleClosePopup}>
                    Cancel
                </MuiButton>
                <Button onClick={onLaunchSurvey}>Launch</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LaunchSurveyPopup;
