import { FC, forwardRef } from 'react';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableHead from '@mui/material/TableHead';
import AutoSizer from 'react-virtualized-auto-sizer';
import MuiTableFooter from '@mui/material/TableFooter';
import MuiTableContainer from '@mui/material/TableContainer';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';

import './Table.scss';
import RowContent from './RowContent';
import TableHeadRow from './TableHeadRow';
import FooterContent from './FooterContent';
import TableHeadGroups from './TableHeadGroups';

const VirtuosoTableComponents: TableComponents = {
    Scroller: forwardRef<HTMLDivElement>(function Scroller(props, ref) {
        return <MuiTableContainer component={Paper} {...props} ref={ref} />;
    }),
    Table: (props) => {
        return <MuiTable {...props} sx={{ borderCollapse: 'separate' }} />;
    },
    TableHead: forwardRef<HTMLTableSectionElement>(function TableHead(props, ref) {
        return <MuiTableHead {...props} ref={ref} />;
    }),
    TableRow: (props) => <MuiTableRow {...props} classes={{ root: 'Table__row' }} />,
    TableBody: forwardRef<HTMLTableSectionElement>(function TableBody(props, ref) {
        return <MuiTableBody {...props} ref={ref} sx={{ position: 'relative' }} />;
    }),
    TableFoot: forwardRef<HTMLTableSectionElement>(function TableFoot(props, ref) {
        return <MuiTableFooter {...props} ref={ref} />;
    }),
};

const Table: FC<{
    rowsCount: number;
    isLoading: boolean;
    noResultsTitle?: string;
    noResultsDescription?: string;
}> = ({
    isLoading,
    rowsCount,
    noResultsTitle,
    noResultsDescription
}) => {
    return (
        <div className="Table">
            <AutoSizer
                // eslint-disable-next-line react/no-children-prop
                children={({
                    height,
                    width
                }) => (
                    <TableVirtuoso
                        totalCount={isLoading ? 0 : rowsCount}
                        style={{ height, width, marginBottom: '20px' }}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={() => (
                            <>
                                <TableHeadGroups />
                                <TableHeadRow />
                            </>
                        )}
                        fixedFooterContent={() => (
                            <FooterContent
                                isLoading={isLoading}
                                noResultsTitle={noResultsTitle}
                                noResultsDescription={noResultsDescription}
                                rowsCount={rowsCount}
                            />
                        )}
                        itemContent={(index) => <RowContent rowIndex={index} />}
                    />
                )}
            />
        </div>
    );
};

export default Table;
