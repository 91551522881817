import { useMemo } from 'react';

import { FCX } from '@models';
import { Route } from '@core/routes/Route';
import useTableSort from '@core/hooks/useTableSort';
import Button, { ButtonType } from '@components/Button';
import { Toggle, ToggleType } from '@components/Toggle';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { formatNumber, replaceRouteParam } from '@core/utils/string';
import { GetChainsResponse, ToggleChainActiveStatusParams } from '@core/api/usePublicItemsLocker';

const PublicItemsLockerTableConfig: FCX<{
    data: GetChainsResponse;
    countryName?: string;
    onToggleChainActiveStatus: (_params: ToggleChainActiveStatusParams) => void;
}> = ({
    data,
    countryName,
    children,
    onToggleChainActiveStatus
}) => {
    const {
        sortedResults,
        sortConfig
    } = useTableSort(data);

    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'countryName',
                title: 'Country Name',
                Value: () => <>{countryName}</>,
                width: '120px',
            },
            {
                key: 'chainId',
                title: 'Chain ID',
                Value: ({ index }) => <>{sortedResults?.[index]?.id}</>,
                width: '90px',
            },
            {
                key: 'chainName',
                sortingColName: 'name',
                title: 'Chain Name',
                Value: ({ index }) => <>{sortedResults?.[index]?.name}</>,
                width: '160px',
            },
            {
                key: 'unitCount',
                sortingColName: 'unitCount',
                title: 'Chain Unit Count',
                Value: ({ index }) => <>{formatNumber(sortedResults?.[index]?.unitCount)}</>,
                width: '120px',
            },
            {
                key: 'segment',
                sortingColName: 'segment',
                title: 'Chain Segment',
                Value: ({ index }) => <>{sortedResults?.[index]?.segment}</>,
                width: '140px',
            },
            {
                key: 'itemCount',
                sortingColName: 'itemCount',
                title: 'Chains Item Count',
                Value: ({ index }) => <>{formatNumber(sortedResults?.[index]?.itemCount)}</>,
                width: '120px',
            },
            {
                key: 'activeChain',
                sortingColName: 'isActive',
                title: 'Active Chain',
                Value: ({ index }) => {
                    return (
                        <Toggle
                            isChecked={sortedResults?.[index]?.isActive ?? false}
                            type={ToggleType.Checkbox}
                            onChange={() => {
                                if (sortedResults?.[index]?.id) {
                                    onToggleChainActiveStatus({
                                        chainId: sortedResults?.[index]?.id as string | undefined,
                                    });
                                }
                            }}
                        ></Toggle>
                    );
                },
                width: '90px',
            },
            {
                key: 'action',
                title: 'Action',
                Value: ({ index }) => {
                    const chainId = sortedResults?.[index]?.id;
                    const chainUrl = replaceRouteParam(Route.ChainItems, ':id', chainId ?? '');
                    return (
                        <Button
                            type={ButtonType.Link}
                            isDisabled={chainId === undefined}
                            link={chainUrl}
                            modifiers={['green', 'rounded']}
                            style={{ fontSize: '13px' }}
                        >
                            View / Edit Items
                        </Button>
                    );
                },
                width: '140px',
            },
        ],
        [countryName, onToggleChainActiveStatus, sortedResults],
    );

    return <TableContext.Provider value={{ config: tableConfig, sortConfig }}>{children}</TableContext.Provider>;
};

export default PublicItemsLockerTableConfig;
