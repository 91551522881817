import useAxios from '@core/hooks/useFetch';
import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@core/models';

import { paths } from './api';

const GET_CHAINS = '/api/PublicItemsLocker/GetChains';
const POST_TOGGLE_ACTIVE_STATUS = '/api/PublicItemsLocker/ToggleIsActiveStatus';

type GetChains = paths[typeof GET_CHAINS][MethodsEnum.GET];
export type GetChainsParams = GetChains[OperationsEnum.PARAMETERS]['query'];
export type GetChainsResponse = GetChains[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];

type PostToggleChainActiveStatus = paths[typeof POST_TOGGLE_ACTIVE_STATUS][MethodsEnum.POST];
export type ToggleChainActiveStatusParams = PostToggleChainActiveStatus[OperationsEnum.PARAMETERS]['query'];

export function useGetPublicItemsLockerChains() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetChainsResponse, GetChainsParams>({
        url: GET_CHAINS,
        method: MethodsEnum.GET,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}

export function useToggleChainActiveStatus() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<boolean>({
        url: POST_TOGGLE_ACTIVE_STATUS,
        method: MethodsEnum.POST,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}
