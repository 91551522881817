import { useMemo, useState } from 'react';

const useTableQuickSearch = <T>(data: T[], accessor: (item: T) => string | null | undefined) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const searchResults = useMemo(() => {
        if (searchValue === '' || searchValue.length < 3) {
            return data;
        }

        return data.filter((item) => {
            const itemAccessor = accessor(item);

            if (!itemAccessor) {
                return false;
            }

            return itemAccessor.toLowerCase().includes(searchValue.toLowerCase());
        });
    }, [data, searchValue, accessor]);

    return {
        searchValue,
        setSearchValue,
        searchResults,
    };
};

export default useTableQuickSearch;
