import { useCallback } from 'react';

import useAxios from '@core/hooks/useFetch';
import { downloadExport } from '@core/utils/file';

const GET_EXPORT_LIVE_SURVEY = '/api/Survey/GetReport';

export function useExportLiveSurveyXls() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<never>({
        url: GET_EXPORT_LIVE_SURVEY,
        lazy: true,
        initialConfig: {
            maxBodyLength: Infinity,
            responseType: 'arraybuffer',
        },
    });

    const doFetchAndDownload = useCallback(() => {
        doFetch().then((resp) => {
            downloadExport(resp, 'SurveysReport.xlsx');
        });
    }, [doFetch]);

    return { data, isLoading, error, doFetchAndDownload } as const;
}
