import useAxios from '@core/hooks/useFetch';
import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@core/models';

import { paths } from './api';

const GET_COMMON = '/api/Common/GetAll';

type GetCommon = paths[typeof GET_COMMON][MethodsEnum.GET];
export type GetCommonResponse = GetCommon[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];

export function useGetCommon() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetCommonResponse>({
        url: GET_COMMON,
        method: MethodsEnum.GET,
    });

    return { data, isLoading, error, doFetch } as const;
}
