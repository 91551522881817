import { FC, useMemo } from 'react';

import TableCell from './TableCell';
import { DEFAULT_ALIGN } from './TableConfig';
import { useTableContext } from './TableContext';

const RowContent: FC<{
    rowIndex: number;
}> = ({ rowIndex }) => {
    const { config } = useTableContext();

    const RowContent = useMemo(
        () =>
            config.map((item, colIndex) => {
                const {
                    Value,
                    align,
                    styles,
                    width
                } = item;
                return (
                    <TableCell
                        key={colIndex}
                        align={align ?? DEFAULT_ALIGN}
                        styles={{
                            ...styles,
                            ...(width ? { minWidth: width, maxWidth: width } : {}),
                        }}
                    >
                        <Value index={rowIndex} />
                    </TableCell>
                );
            }),
        [config, rowIndex],
    );

    return <>{RowContent}</>;
};

export default RowContent;
