import { useState } from 'react';

import { FCX } from '@core/models';
import { TextInput } from '@components/Inputs';
import { TextInputType } from '@components/Inputs/TextInput/TextInput';

const FULL_DEFAULT_SAMPLE = '1000';
const DESIRED_SAMPLE_DEFAULT = '300';

function isTestSampleFull(testType: string) {
    return testType !== 'Regular' && testType !== 'Express';
}

const DesiredSampleInput: FCX<{
    onChange: (_v: string) => void;
    testType?: string;
}> = ({
    onChange,
    testType = ''
}) => {
    const [desiredSample, setDesiredSample] = useState(
        isTestSampleFull(testType) ? FULL_DEFAULT_SAMPLE : DESIRED_SAMPLE_DEFAULT,
    );
    return (
        <TextInput
            type={TextInputType.NUMBER}
            value={desiredSample}
            setValue={(value) => {
                setDesiredSample(value);
                onChange(value);
            }}
            isSmall
        />
    );
};

export default DesiredSampleInput;
