import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import { Sort, SortOrder } from '@components/Table/TableConfig';

export type useTableSortReturn<T> = {
    sortedResults: T[];
    sortConfig: {
        sortedColumn: Sort | undefined;
        onClearSort: () => void;
        onSort: (field: string) => void;
    };
};

const NEXT_SORT_DIRECTION = {
    [SortOrder.Asc]: SortOrder.Desc,
    [SortOrder.Desc]: SortOrder.Asc,
};

function useTableSort<T extends Record<string, unknown>>(
    data: T[],
    defaultSortedField?: string,
): useTableSortReturn<T> {
    const [sortedColumn, setSortedColumn] = useState<Sort | undefined>(
        defaultSortedField ? { field: defaultSortedField, order: SortOrder.Desc } : undefined,
    );

    const onSort = useCallback((field: string) => {
        setSortedColumn((prevSort) => {
            if (!prevSort) {
                return {
                    field,
                    order: SortOrder.Desc,
                };
            }

            const isSameField = prevSort.field === field;

            const newSort = {
                field,
                order: isSameField ? NEXT_SORT_DIRECTION[prevSort.order as SortOrder] : SortOrder.Desc,
            };

            return newSort;
        });
    }, []);

    const sortedResults = useMemo(() => {
        if (!sortedColumn) {
            return data;
        }

        return [...data].sort((a, b) => {
            const aValue = a[sortedColumn.field];
            const bValue = b[sortedColumn.field];

            // If one of the values is undefined or null, we will put it at the end of the list
            if (aValue === null || aValue === undefined) {
                return 1;
            }

            if (bValue === null || bValue === undefined) {
                return -1;
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                if (dayjs(aValue).isValid() && dayjs(bValue).isValid()) {
                    return sortedColumn.order === SortOrder.Asc
                        ? dayjs(aValue).isAfter(dayjs(bValue))
                            ? 1
                            : -1
                        : dayjs(bValue).isAfter(dayjs(aValue))
                            ? 1
                            : -1;
                }

                return sortedColumn.order === SortOrder.Asc
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return sortedColumn.order === SortOrder.Asc ? aValue - bValue : bValue - aValue;
            }

            if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
                return sortedColumn.order === SortOrder.Asc ? (aValue ? 1 : -1) : bValue ? 1 : -1;
            }

            return 0;
        });
    }, [data, sortedColumn]);

    const onClearSort = useCallback(() => {
        setSortedColumn(undefined);
    }, []);

    return {
        sortConfig: {
            sortedColumn,
            onClearSort,
            onSort,
        },
        sortedResults,
    };
}

export default useTableSort;
