import { useCallback, useMemo } from 'react';
import { IconButton } from '@mui/material';

import { FCX } from '@models';
import Button from '@components/Button';
import { IconPencil } from '@svg/index';
import useTableSort from '@core/hooks/useTableSort';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { formatDefaultDate, formatNumber } from '@core/utils/string';
import useCommonEnums, { SurveyStatusIdEnum } from '@core/hooks/useCommonEnums';
import { ExtendedSurvey, GetSurveyInitialDataResponse, GetSurveysResponse } from '@core/api/useSurveys';

const SurveysTableConfig: FCX<{
    actions: {
        onEdit: (_survey: ExtendedSurvey) => void;
        onLaunch: (_survey: ExtendedSurvey) => void;
        onClose: (_survey: ExtendedSurvey) => void;
    };
    customFielding: GetSurveyInitialDataResponse['customFielding'];
    data: GetSurveysResponse;
}> = ({
    actions,
    children,
    customFielding,
    data
}) => {
    const { findCommonNameById } = useCommonEnums();

    const {
        sortedResults,
        sortConfig
    } = useTableSort(data);

    const getCustomFielding = useCallback(
        (id?: number | null): string => customFielding?.find((cf) => cf.id === id)?.name ?? '',
        [customFielding],
    );

    const tableRowColor = useCallback((survey: ExtendedSurvey) => {
        if (survey?.statusId === SurveyStatusIdEnum.Draft) {
            return { color: '#00a3ff' };
        }

        if (survey?.statusId === SurveyStatusIdEnum.Live) {
            return { color: '#00AE7A' };
        }

        return {};
    }, []);

    const renderActionButton = useCallback(
        (survey: ExtendedSurvey) => {
            if (survey?.statusId === SurveyStatusIdEnum.Draft) {
                return (
                    <Button
                        className="Surveys__button-launch"
                        modifiers={['rounded']}
                        onClick={() => actions.onLaunch(survey)}
                    >
                        Launch
                    </Button>
                );
            }

            if (survey?.statusId === SurveyStatusIdEnum.Live) {
                return (
                    <Button
                        className="Surveys__button-close"
                        modifiers={['rounded']}
                        onClick={() => actions.onClose(survey)}
                    >
                        Close
                    </Button>
                );
            }

            return null;
        },
        [actions],
    );

    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'surveyId',
                title: 'Survey ID',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>{sortedResults?.[index]?.id}</div>
                ),
                width: '70px',
            },
            {
                key: 'fieldingName',
                sortingColName: 'name',
                title: 'Fielding Name',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>{sortedResults?.[index]?.name}</div>
                ),
                width: '140px',
            },
            {
                key: 'dateCreated',
                sortingColName: 'createdDate',
                title: 'Date Created',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatDefaultDate(sortedResults?.[index]?.createdDate)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'fieldingStartDate',
                sortingColName: 'startDate',
                title: 'Fielding Start Date',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatDefaultDate(sortedResults?.[index]?.startDate)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'fieldingEndDate',
                sortingColName: 'endDate',
                title: 'Fielding End Date',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatDefaultDate(sortedResults?.[index]?.endDate)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'status',
                title: 'Status',
                sortingColName: 'statusId',
                Value: ({ index }) => {
                    if (sortedResults?.[index]?.statusId !== undefined) {
                        return (
                            <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                                {findCommonNameById('surveyStatuses', sortedResults[index].statusId)}
                            </div>
                        );
                    }

                    return null;
                },
                width: '65px',
            },
            {
                key: 'express',
                sortingColName: 'expressCount',
                title: 'Express',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.expressCount)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'fullTest',
                sortingColName: 'fullTestCount',
                title: 'Full Test',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.fullTestCount)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'publicItems',
                sortingColName: 'publicItemsCount',
                title: 'Public Items',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.publicItemsCount)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'totalItems',
                sortingColName: 'totalItemsCount',
                title: 'Total Items',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.totalItemsCount)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'sampleSize',
                sortingColName: 'completedSampleSize',
                title: 'Sample Size',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {formatNumber(sortedResults?.[index]?.completedSampleSize)}
                    </div>
                ),
                width: '70px',
            },
            {
                key: 'customSampleType',
                title: 'Custom Sample Type',
                Value: ({ index }) => (
                    <div style={{ ...tableRowColor(sortedResults?.[index]) }}>
                        {getCustomFielding(sortedResults?.[index]?.customSampleTypeId)}
                    </div>
                ),
                width: '80px',
            },
            {
                key: 'action',
                width: '120px',
                title: 'Actions',
                Value: ({ index }) => (
                    <div className="Surveys__table-actions">
                        <IconButton onClick={() => actions.onEdit(sortedResults?.[index])}>
                            <IconPencil />
                        </IconButton>
                        {renderActionButton(sortedResults?.[index])}
                    </div>
                ),
            },
        ],
        [actions, findCommonNameById, getCustomFielding, renderActionButton, sortedResults, tableRowColor],
    );

    return <TableContext.Provider value={{ config: tableConfig, sortConfig }}>{children}</TableContext.Provider>;
};

export default SurveysTableConfig;
